import React, {useContext, useState, useEffect,} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FirebaseContext from "../../../../Context/Firebase";
import {useFormik} from 'formik';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as Yup from 'yup';
import Button from "@material-ui/core/Button";
import LinearProgress from '@material-ui/core/LinearProgress';
import {DebounceInput} from 'react-debounce-input';

const useStyles = makeStyles(() => ({
  itemWrap: {
    padding: '10px',
    width: "180px",
    cursor: 'pointer',
    height: "180px",
  },
  loadingWrap: {
    textAlign: 'center',
    width: '100%',
    padding: '10px 0'
  },
  item: {
    border: '1px solid #ccc',
    borderRadius: '10px',
    width: '100%',
    height: '100%',
    textAlign: 'center'
  },
  appIconWrap: {
    textAlign: 'center',
  },
  appIcon: {
    textAlign: 'center',
    fontSize: '30px',
    marginTop: '30px'
  },
  plusIcon: {
    marginTop: '15px',
    paddingTop: '10px',
    borderTop: '1px solid #ccc'
  },
  modalContentWrap: {
    minWidth: '300px',
    width: '90vw',
    maxWidth: '550px',

  },
  modalTitle: {
    padding: '5px 10px',
    margin: '0 auto 10px auto',
    borderBottom: '1px solid #ccc'
  },
  appDescription: {
    fontSize: '12px',
    color: '#8e8e8e'
  },
  appTitle: {
    fontSize: '17px'
  },
  errMsg: {
    float: 'left',
    color: '#f53838',
    textIndent: '14px'
  },
  floatRight: {
    float: 'right'
  },
  clearBoth: {
    clear: 'both'
  }

}));

export default function WebListManageModal(props) {
  const firebase = useContext(FirebaseContext);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [ogImgUrl, setOgImgUrl] = useState('');
  const {
    isModal,
    closeModal,
    eventInfo,
    webListInfo,
    menuId,
    orderCount
  } = props;


  useEffect(() => {
    if (webListInfo) {
      setOgImgUrl(webListInfo.imgUrl)
    }

  }, [])

  const onSubmit = (values, {setSubmitting}) => {
    console.log('values', values)
    console.log('ogImgUrl', ogImgUrl)
    if (webListInfo) {
      firebase.firestore().collection('Events')
        .doc(eventInfo.id)
        .collection('WebLinks')
        .doc(webListInfo.id)
        .update({
          url: values.url,
          title: values.title,
          description: values.description,
          style: {},
          imgUrl: ogImgUrl || '',
        }).then(() => {
        setSubmitting(false)
        closeModal();
      })
    } else {
      const newDocRef = firebase.firestore().collection('Events')
        .doc(eventInfo.id).collection('WebLinks').doc();
      newDocRef.set({
        id: newDocRef.id,
        eventId: eventInfo.id,
        createdAt: new Date(),
        menuId: menuId,
        url: values.url,
        title: values.title,
        description: values.description,
        style: {},
        imgUrl: ogImgUrl || '',
        order: orderCount + 1
      })
        .then(() => {
          closeModal();
        })
    }

  }

  const {
    values,
    setFieldValue,
    errors,
    validateForm,
    touched,
    setFieldTouched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting
  } = useFormik({
    initialValues: {
      url: webListInfo ? webListInfo.url : '',
      title: webListInfo ? webListInfo.title : '',
      description: webListInfo ? webListInfo.description : ''
    },
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .required('제목을 입력해주세요.')
        .max(100, '50자 이하로 입력해주세요.'),
      description: Yup.string()
        .max(200, '100자 이하로 입력해주세요.'),
      url: Yup.string()
        .required('URL을 입력해주세요.')
        .url('URL형식(http(s)://포함)으로 입력해주세요.')
    }),
    onSubmit
  })

  const callSiteMetaInfoGetFun = () => {
    setIsLoading(true);
    validateForm().then((r) => {
      let isUrlCorrect = true;
      const errorArray = Object.keys(r);
      if (errorArray.includes('url')) {
        setFieldTouched('url', true)
        isUrlCorrect = false;
      }
      if (isUrlCorrect) {
        const siteMetaInfoFun = firebase.functions().httpsCallable('getOpenGraph');
        siteMetaInfoFun({url: values.url})
          .then((result) => {
            console.log('getOpenGraphRusult', result);
            if (result.data.success) {
              setOgImgUrl(result.data.ogImage ? result.data.ogImage.url : '');
              setFieldValue('title', result.data.ogTitle ? result.data.ogTitle : '');
              setFieldValue('description', result.data.ogDescription ? result.data.ogDescription : '');
              setIsLoading(false);
            } else {
              setOgImgUrl('');
              setFieldValue('title', '');
              setFieldValue('description', '');
              setIsLoading(false);
            }
          }).catch(() => {
          setIsLoading(false);
        })
      } else {
        setIsLoading(false);
      }
    })
  }

  return (
    <div>
      <Dialog
        open={isModal}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogContent>
          <div className={classes.modalTitle}>
            <Typography variant="h6" className={classes.eventInfoTitle}>
              웹리스트
            </Typography>
          </div>
          <div className={classes.modalContentWrap}>
            {
              !isLoading
                ? ''
                : (<OnLoadingState/>)
            }

            {
              ogImgUrl && <div style={{textAlign: 'center'}}>
                <img
                  style={{width: '250px'}}
                  src={ogImgUrl}
                  alt={values.title || ''}
                  onError={() => setOgImgUrl('')}
                />
              </div>
            }

            <form onSubmit={handleSubmit}>
            <TextField
                name="url"
                disabled={isLoading}
                onBlur={callSiteMetaInfoGetFun}
                value={values.url}
                onChange={handleChange}
                variant="outlined"
                label="링크 URL"
                placeholder="URL을 입력해주세요(http(s)://포함)"
                margin="normal"
                fullWidth
                error={touched.url && !!errors.url}
                helperText={touched.url && !!errors.url ? errors.url : ''}
              />
              <TextField
                name="title"
                disabled={isLoading}
                onBlur={handleBlur}
                value={values.title}
                label="제목"
                placeholder="제목을 입력해주세요"
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={touched.title && !!errors.title}
                helperText={touched.title && !!errors.title ? errors.title : ''}
                margin="normal"
              />
              <TextField
                name="description"
                disabled={isLoading}
                onBlur={handleBlur}
                value={values.description}
                label="설명"
                placeholder="설명을 입력해주세요"
                onChange={handleChange}
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                margin="normal"
                error={touched.description && !!errors.description}
                helperText={touched.description && errors.description ? errors.description : ''}
              />
              <div style={{textAlign: 'right', padding: '20px 0'}}>
                <Button
                  type="button"
                  variant="contained"
                  color="default"
                  className={classes.submit}
                  size="large"
                  style={{marginRight: '10px'}}
                  onClick={() => closeModal()}
                >
                  취소
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  size="large"
                  disabled={isSubmitting}>
                  등록
                </Button>
              </div>
            </form>
          </div>
        </DialogContent>


      </Dialog>

    </div>
  );
}

const OnLoadingState = () => {
  return (
    <div style={{padding: '10px 0', textAlign: 'center'}}>
      <LinearProgress/>
      <Typography variant="body1" style={{marginTop: '10px'}}>사이트 정보를 가져오고 있습니다.</Typography>
    </div>
  )
}
