import React, {useContext} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {DebounceInput} from "react-debounce-input";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FirebaseContext from "../../../../Context/Firebase";
import {getExtensionOfFilename,} from '../../../../Utill/Functions';

import {allowedProgramFileTypes} from '../../../../Constant/SiteConfig';

const useStyles = makeStyles(() => ({
  optionBtn: {
    margin: '10px 0 0 10px'
  },
  fileUploadWrap: {
    border: '1px solid #ccc',
    padding: '10px',
    borderRadius: '5px'
  },
  uploadBtn: {
    backgroundColor: '#fff',
    color: '#000',
    padding: 10,
    borderRadius: 4,
    position: 'relative',
    top: '20px',
    left: '10px',
    border: '1px solid #000',
    cursor: 'pointer'
  },
  loadingWrap: {
    textAlign: 'center',
    width: '100%',
    marginTop: '20px',
    padding: '10px 0'
  },

}));
export default function FileUploadForm(props) {
  const {
    fileTitle,
    setSelectedFile,
    changeUploadFileTitle,
    closeFileUploadAddForm,
    fileUploadOnCreate,
  } = props;
  const classes = useStyles();
  const onChangFileForm = (event) => {
    if (!event.target.files[0]) {
      event.target.value = null;
      return false;
    }
    const uploadedFileType = getExtensionOfFilename(event.target.files[0].name);
    if( !allowedProgramFileTypes.includes(uploadedFileType)){
      alert('업로드 가능한 파일형태가 아닙니다.');
      event.target.value = null;
      return false;
    }
    if (event.target.files[0].size > 52428800) {
      alert('50MB이하의 파일을 업로드해주세요.');
      event.target.value = null;
      return false;
    }
    setSelectedFile(event.target.files[0]);
  }


  return (
    <>
      <Box mt={2} mb={2} className={classes.fileUploadWrap}>
        <Grid container>
          <Grid item xs={4}>
            <DebounceInput
              element={TextField}
              debounceTimeout={500}
              label="파일명"
              type="text"
              name="fileTitle"
              value={fileTitle}
              onChange={changeUploadFileTitle}
              fullWidth
            />
          </Grid>
          <Grid item xs={8}>
            <input
              accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,image/jpeg,image/jpg,image/gif,image/png,application/pdf,application/vnd.ms-word,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              type="file"
              name="fileForm"
              id="fileForm"
              onChange={onChangFileForm}
              style={{
                margin:'26px 0 0 11px',
                cursor:'pointer'
              }}
            />

          </Grid>
        </Grid>


        <div style={{textAlign: 'right'}}>

          <Button
            color="default"
            variant="contained"
            onClick={closeFileUploadAddForm}
            className={classes.optionBtn}>
            취소
          </Button>

          <Button
            color="secondary"
            variant="contained"
            className={classes.optionBtn}
            onClick={fileUploadOnCreate}
          >
            추가
          </Button>

        </div>
      </Box>
    </>
  )

}
