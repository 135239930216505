import React, {useState, useEffect, useContext} from 'react';
import FirebaseContext from "../../../../Context/Firebase";
import {makeStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {Field, Formik, Form,} from 'formik';
import {TextField,} from 'formik-material-ui';
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from '@material-ui/icons/Visibility';
import * as Yup from 'yup';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    alignItems: 'center',
  },
  commentDisplayName: {
    fontWeight: 'bold',
    fontSize: '15px',
    marginRight: '10px'
  },
  commentItemWrap: {
    padding: '5px 0'
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  postControlArea: {
    textAlign: 'right',
    marginBottom: '10px'
  },
  targetCommentInfoWrap: {
    border: '1px solid #F44336',
    padding: '10px',
    borderRadius: '5px',
    marginTop: '35px'
  },
  textLineThrough: {
    textDecoration: 'line-through'
  },
  reportItem: {
    display: 'inline-block',
    padding: '4px 20px',
    background: '#f50659',
    color: '#fff',
    fontSize: '15px',
    fontWeight: 'bold',
    borderRadius: '5px',
    marginRight: '6px'
  },


}));
export default function PostDetailComments(props) {
  const {eventInfo, postId, reportInfo,} = props;
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const [isLoading, setIsLoading] = useState(true)
  const [postComment, setPostComment] = useState(null);
  const [isCommentHideForm, setIsCommentHideForm] = useState(false);
  const [selectedCommentItem, setSelectedCommentItem] = useState(null);


  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('Events')
      .doc(eventInfo.id)
      .collection('Posts')
      .doc(postId)
      .collection('Comments')
      .orderBy('createdAt', 'asc')
      .onSnapshot((docResult) => {
        const resultData = [];
        docResult.forEach((doc) => {
          resultData.push(doc.data())
        })
        setPostComment(resultData);
        setIsLoading(false)
      })

    return () => {
      unsubscribe();
    }

  }, [])

  const callCommentHideFun = (targetCommentItem) => {
    setSelectedCommentItem(targetCommentItem);
    setIsCommentHideForm(true)
  }
  const cancelCommentHide = (targetCommentItem) => {
    if (window.confirm('해당 댓글을 노출하시겠습니까?')) {
      firebase.firestore().collection('Events')
        .doc(targetCommentItem.eventId)
        .collection('Posts')
        .doc(targetCommentItem.postId)
        .collection('Comments')
        .doc(targetCommentItem.id)
        .set({
          isHide: false,
          commentHideReason: ''
        }, {merge: true})
    }
  }

  return !isLoading ? (
      <>
        {
          postComment && postComment.length > 0 ?
          postComment.map((item) => (
            <Grid container className={`${classes.root} ${classes.commentItemWrap}`} key={item.id}>
              <Grid item xs={3}>
                <p className={item.isHide ? classes.textLineThrough : classes.commentDisplayName}>
                  {item.displayName}
                </p>
              </Grid>
              <Grid item xs={8}>

                {reportInfo && reportInfo.targetId === item.id
                  ? <span className={classes.reportItem}>신고</span>
                  : ''
                }
                <span className={item.isHide ? classes.textLineThrough : ''}>
                  {item.text}
                </span>

              </Grid>
              <Grid item xs={1} style={{textAlign: 'center'}}>
                {
                  item.isHide
                    ? (
                      <Tooltip title="해당 댓글 노출하기" placement="left-start">
                        <VisibilityIcon
                          className={classes.cursorPointer}
                          onClick={() => cancelCommentHide(item)}
                        />
                      </Tooltip>

                    )
                    : (
                      <Tooltip title="해당 댓글 감추기" placement="left-start">
                        <VisibilityOffIcon
                          className={classes.cursorPointer}
                          onClick={() => callCommentHideFun(item)}
                        />
                      </Tooltip>
                    )
                }
              </Grid>

              {isCommentHideForm && selectedCommentItem.id === item.id && (
                <Grid item xs={12}>
                  <CommentHiddenForm
                    selectedCommentItem={selectedCommentItem}
                    setSelectedCommentItem={setSelectedCommentItem}
                    setIsCommentHideForm={setIsCommentHideForm}
                    classes={classes}
                  />
                </Grid>
              )}
            </Grid>
          ))
          : <p>등록된 댓글이 없습니다.</p>
        }

      </>
    )
    :
    (
      <div className={classes.loadingWrap}>
        <CircularProgress/>
      </div>
    )
}


const CommentHiddenForm = (props) => {
  const firebase = useContext(FirebaseContext);
  const {
    setSelectedCommentItem,
    setIsCommentHideForm,
    classes,
    selectedCommentItem
  } = props;
  const validationSchema = Yup.object().shape({
    commentHideReason: Yup.string()
      .required('댓글 감추기 사유를 입력해주세요')
      .max(100, '100자 이하로 입력해주세요.')
  })
  const closeCommentHideForm = () => {
    setSelectedCommentItem(null);
    setIsCommentHideForm(false)
  }

  const onCommentHideSubmit = (value) => {

    firebase.firestore().collection('Events')
      .doc(selectedCommentItem.eventId)
      .collection('Posts')
      .doc(selectedCommentItem.postId)
      .collection('Comments')
      .doc(selectedCommentItem.id)
      .set({
        isHide: true,
        commentHideReason: value.commentHideReason
      }, {merge: true})
      .then(() => {
        setIsCommentHideForm(false)
      })
  }
  return (
    <div className={classes.postControlArea}>
      <Formik
        initialValues={{commentHideReason: ''}}
        validationSchema={validationSchema}
        onSubmit={onCommentHideSubmit}
      >
        {
          () => (
            <Form>
              <Field
                disabled={false}
                component={TextField}
                variant="outlined"
                margin="normal"
                label="댓글 감추기 사유"
                name="commentHideReason"
                placeholder="댓글 감추기 사유를 입력해주새요"
                fullWidth
              />
              <Button
                type="button"
                variant="contained"
                color="default"
                style={{marginRight: '5px'}}
                className={classes.button}
                onClick={() => closeCommentHideForm(false)}
              >
                취소
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
              >
                댓글 감추기
              </Button>

            </Form>
          )
        }
      </Formik>
    </div>
  )
}
