import React, {useState, useEffect, useContext} from 'react';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from "react-grid-dnd";
import {useHistory, useRouteMatch} from 'react-router-dom';
import Icon from 'react-eva-icons';
import {makeStyles} from "@material-ui/core/styles";
import FirebaseContext from "../../../Context/Firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import {appCollectionTitle} from "../../../Constant/SiteConfig";

const useStyles = makeStyles(() => ({
  itemWrap: {
    padding: '10px',
    width: "100%",
    cursor: 'pointer',
    height: "180px",
  },
  item: {
    border: '1px solid #ccc',
    borderRadius: '10px',
    fontSize: '16px',
    width: '100%',
    height: '100%',
    textAlign: 'center'
  },
  appIconWrap: {
    textAlign: 'center',
    marginTop: '15px'
  },
  noticeAppIconWrap: {
    textAlign: 'center',
    marginTop: '35px'
  },
  appIcon: {
    textAlign: 'center',
    fontSize: '45px',
    marginTop: '40px'
  },
  appMenuEditWrap: {
    display: 'flex',
    marginTop: '15px',
    paddingTop: '9px',
    borderTop: '1px solid #ccc'
  },
  noticeAppMenuManageDiv: {
    width: '100%',
    textAlign: 'center',
    "&:hover": {
      fontWeight: 'bold'
    }
  },
  appMenuLeftEditDiv: {
    width: '50%',
    borderRight: '1px solid #ccc',
    "&:hover": {
      fontWeight: 'bold'
    }
  },
  appMenuRightEditDiv: {
    width: '50%',
    "&:hover": {
      fontWeight: 'bold'
    }
  },
  loadingWrap: {
    textAlign: 'center',
    paddingTop: '30px'
  }

}));


export default function DragableAppMenu(props) {
  const {eventInfo, needUpdate, setNeedUpdate,} = props;
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const [isLoading, setIsLoading] = useState(true);
  const [neatMenuList, setNeatMenuList] = useState([]);
  const [dragZoneHeight, setDragZoneHeight] = useState();
  const history = useHistory();
  const {url} = useRouteMatch();
  useEffect(() => {
    setIsLoading(true);
    const neatMenu = Object.values(eventInfo.menus).sort((a, b) => a.order - b.order).map((item) => item.id)
    setNeatMenuList(neatMenu)
    setDragZoneHeight((((Object.keys(neatMenu).length / 3) + 1) * 180))
    setIsLoading(false);
  }, [needUpdate])

  const editAppMenu = (item) => {
    switch (item.type) {
      case 'INFO':
        history.push(`${url}/eventBasicInfo`);
        break;
      case 'NOTICE':
        history.push(`${url}/noticeManage?key=${item.id}`);
        break;
      case 'AGENDA':
        history.push(`${url}/agendaManage?key=${item.id}`);
        break;
      case 'COMMUNITY':
        history.push(`${url}/postManage?key=${item.id}`);
        break;
      case 'WEB_VIEW':
        history.push(`${url}/webviewManage?key=${item.id}`)
        break;
      case 'WEB_LIST':
        history.push(`${url}/weblistManage?key=${item.id}`)
        break;
      default:
        return false;
    }


  }

  const updateAppMenuListOrder = (newAppMenuList) => {
    let menusData = eventInfo.menus;
    Object.values(menusData).map((itemValue) => {
      newAppMenuList.map((value) => {
        if (itemValue.id === value) {
          menusData[value].order = newAppMenuList.indexOf(value) + 1
        }
      })
    })
    firebase.firestore().collection('Events')
      .doc(eventInfo.id)
      .update({
        menus: menusData,
      })
  }


  const onChange = (sourceId, sourceIndex, targetIndex,) => {
    const nextState = swap(neatMenuList, sourceIndex, targetIndex);
    setNeatMenuList(nextState);
    updateAppMenuListOrder(nextState)
  }

  const deleteAppMenu = (appMenuId) => {
    if (window.confirm('해당 메뉴를 삭제하시겠습니까?')) {
      setIsLoading(true)

      try {
        firebase.firestore().collection('Events')
          .doc(eventInfo.id)
          .collection(appCollectionTitle[appMenuId.type])
          .where('menuId', '==', appMenuId.id)
          .get()
          .then((result) => {
            const targetDeleteDocId = [];
            result.forEach((doc) => {
              targetDeleteDocId.push(doc.data().id)
            })
            if (targetDeleteDocId && targetDeleteDocId.length > 0) {
              const batch = firebase.firestore().batch();
              targetDeleteDocId.map((deleteValue) => {
                let valueRef = firebase.firestore().collection('Events')
                  .doc(eventInfo.id)
                  .collection(appCollectionTitle[appMenuId.type])
                  .doc(deleteValue);
                batch.delete(valueRef);
              });
              batch.commit()
            }
          })
      } catch (e) {

      }
      let menusData = eventInfo.menus;
      Object.values(menusData).map((itemValue) => {
        if (itemValue.order > appMenuId.order) {
          menusData[itemValue.id].order = menusData[itemValue.id].order - 1
        }
      })
      firebase.firestore().collection('Events')
        .doc(eventInfo.id)
        .update({
            menus: menusData,
          }
        ).then(() => {
        firebase.firestore().collection('Events')
          .doc(eventInfo.id)
          .update({
            ['menus.' + appMenuId.id]: firebase.firestore.FieldValue.delete()
          })
          .then(() => {
            setNeedUpdate(!needUpdate)
            setIsLoading(false)
          })
      })

    }
  }


  return (
    <>
      <GridContextProvider onChange={onChange}>
        <GridDropZone
          id="items"
          boxesPerRow={3}
          rowHeight={180}
          style={{height: dragZoneHeight,}}
        >
          {!isLoading ?
            neatMenuList.map((item) => (
              <GridItem key={item}>
                <div className={classes.itemWrap}>
                  <div className={classes.item}>
                    <div
                      className={classes.appIconWrap}>
                      <Icon
                        name={eventInfo.menus[item].icon}
                        fill="#373737"
                      />
                    </div>
                    {eventInfo.menus[item].title}

                    {
                      eventInfo.menus[item].type === 'NOTICE'
                        ? (
                          <div className={classes.appMenuEditWrap}>
                            <div className={classes.noticeAppMenuManageDiv}
                                 onClick={() => editAppMenu(eventInfo.menus[item])}>관리
                            </div>
                          </div>
                        )
                        : (
                          <div className={classes.appMenuEditWrap}>
                            <div className={classes.appMenuLeftEditDiv}
                                 onClick={() => editAppMenu(eventInfo.menus[item])}>편집
                            </div>
                            <div className={classes.appMenuRightEditDiv}
                                 onClick={() => deleteAppMenu(eventInfo.menus[item])}>삭제
                            </div>
                          </div>
                        )
                    }


                  </div>
                </div>
              </GridItem>
            ))
            : (<div className={classes.loadingWrap}>
              <CircularProgress/>
            </div>)

          }
        </GridDropZone>
      </GridContextProvider>

    </>
  );
}


