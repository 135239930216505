import React, {useState, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {TextField} from 'formik-material-ui';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import * as Yup from 'yup';
import {Field, Formik, Form} from 'formik';
import * as Routes from '../../Constant/Routes';
import FirebaseContext from '../../Context/Firebase';
import AuthenticationContext from '../../Context/Authentication';

import LoadingOverlay from "../../Component/LoadingOverlay/Index";
import logo from "../../images/logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: '280px',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function FindPassword(props) {
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthenticationContext);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required('현재 비밀번호를 입력해주세요.'),
    newPassword: Yup.string()
      .min(6, '비밀번호는 6자 이상 입력해주세요')
      .max(15, '비밀번호는 15자 이하로 입력해주세요')
      .required('새로운 비밀번호를 입력해주세요.'),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], '입력하신 비밀번호와 일치하지 않습니다.')
      .required('새로운 비밀번호를 다시 입력해주세요.'),

  });

  const onSubmit = (values) => {
    setIsLoading(true);
    firebase.auth()
      .signInWithEmailAndPassword(authUser, values.currentPassword)
      .then(() => {
        firebase.auth().currentUser.updatePassword(values.newPassword).then(() => {
          window.alert('비밀번호가 정상적으로 변경되었습니다.');
          history.push(Routes.DashBoard);
        }).catch((err) => {
          console.log('updatePasswdErr', err);
        });
      }).catch(() => {
      window.alert('현재 비밀번호가 일치하지 않습니다.');
      setIsLoading(false);
    });
  };


  return (
    <>
      <Grid container component="main" className={classes.root}>
        <CssBaseline/>
        <Grid item xs={false} sm={false} md={7} className={classes.image}/>
        <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
              Change Password
            </Typography>

            <Formik
              initialValues={{ currentPassword: '', newPassword: '', confirmNewPassword: ''}}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {() => (
                <Form className={classes.form}>
                  <Field
                    disabled={false}
                    component={TextField}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="현재 비밀번호를 입력해주세요."
                    type="password"
                    name="currentPassword"
                  />
                  <Field
                    disabled={false}
                    component={TextField}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    type="password"
                    label="새로운 비밀번호를 입력해주세요."
                    name="newPassword"
                  />

                  <Field
                    disabled={false}
                    component={TextField}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    type="password"
                    label="새로운 비밀번호를 재입력해주세요."
                    name="confirmNewPassword"
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    size="large"
                  >

                    비밀번호 변경하기
                  </Button>
                </Form>
              )}
            </Formik>

          </div>
        </Grid>
      </Grid>
      {isLoading ? (<LoadingOverlay/>) : ''}
    </>
  );
}

