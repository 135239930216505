import React from 'react';
import Box from "@material-ui/core/Box";
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    marginTop: '7px'
  },
  tal: {
    textAlign: 'left',
    fontSize: '16px',
    paddingLeft: '10px!important'
  },
  tac: {
    textAlign: 'center',
    fontSize: '15px'
  },
  tar: {
    textAlign: 'right',
    paddingRight: '10px!important'
  },
  listEditBtn: {
    display: 'inline-block',
    cursor: 'pointer',
    padding: '0px 10px',
    margin: '0px 3px',
    "&:hover": {
      fontWeight: 'bold'
    }

  }
}));
export default function OutsideLinkList(props) {
  const {outsideLinkObject, onRemove, onEdit} = props;
  const classes = useStyles();


  return (
    <Box mt={2}>
      {Object.values(outsideLinkObject).map((item) => (
          <Grid container className={classes.root} spacing={1} key={item.id}>
            <Grid item xs={4} className={classes.tal}>
              {item.title}
            </Grid>
            <Grid item xs={4} className={classes.tac}>
              {item.url}
            </Grid>
            <Grid item xs={4} className={classes.tar}>
              <p
                onClick={() => onEdit(item)}
                className={classes.listEditBtn}
              >
                수정
              </p>
              |
              <p
                className={classes.listEditBtn}
                onClick={() => onRemove(item.id)}
              >삭제
              </p>

            </Grid>
          </Grid>
        )
      )}
    </Box>
  )

}
