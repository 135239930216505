import React, {useState, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {TextField} from 'formik-material-ui';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import * as Yup from 'yup';
import {Field, Formik, Form} from 'formik';
import * as Routes from '../../Constant/Routes';
import FirebaseContext from '../../Context/Firebase';
import SubLink from "./SubLink";
import JoinPageLink from "./JoinPageLink";
import LoadingOverlay from "../../Component/LoadingOverlay/Index";
import * as Functions from '../../Utill/Functions';
import logo from "../../images/logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: '280px',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function FindAuth(props) {
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('성을 입력해주세요'),
    lastName: Yup.string()
      .required('이름을 입력해주세요'),

  });

  const onSubmit = (values) => {
    setIsLoading(true);
    const {firstName, lastName} = values;
    firebase.firestore().collection('OrganizerUsers')
      .where('firstName', '==', firstName)
      .where('lastName', '==', lastName)
      .get()
      .then((result) => {
        if (result.docs.length > 0) {
          setIsLoading(false);
          result.forEach((doc) => {
            const resultEmailAddress = Functions.replaceEmailInfo(doc.data().email);
            window.alert(`가입된 계정은 ${resultEmailAddress}입니다.`);
          });
        } else {
          setIsLoading(false);
          window.alert('입력하신 정보와 일치하는 계정이 없습니다.');
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  return (
    <>
      <Grid container component="main" className={classes.root}>
        <CssBaseline/>
        <Grid item xs={false} sm={false} md={7} className={classes.image}/>
        <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
              Find Account
            </Typography>

            <Formik
              initialValues={{firstName: '', lastName: ''}}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {() => (
                <Form className={classes.form}>
                  <Field
                    disabled={false}
                    component={TextField}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="성을 입력해주세요"
                    name="firstName"
                  />
                  <Field
                    disabled={false}
                    component={TextField}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="lastName"
                    label="이름을 입력해주세요"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    size="large"
                  >
                    계정찾기
                  </Button>
                </Form>
              )}
            </Formik>

            <SubLink
              viewComponent={props.viewComponent}
              setViewComponent={props.setViewComponent}
            />
          </div>
          <JoinPageLink/>
        </Grid>
      </Grid>
      {
        isLoading ? (<LoadingOverlay/>) : ''
      }
    </>
  );
}

