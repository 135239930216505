import React, {useEffect, useState, useContext} from 'react';
import {useLocation, useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {TextField,} from 'formik-material-ui';
import {Field, Formik, Form,} from 'formik';
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import AppMenuTitleEditable from "../../../../Component/AppMenuTitleEditable/Index";
import Button from "@material-ui/core/Button";
import * as Yup from 'yup';
import {DashBoard} from '../../../../Constant/Routes'
import FirebaseContext from "../../../../Context/Firebase";
import useToast from "../../../../Hook/useToast";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
  eventInfoTitle: {
    paddingTop: '10px'
  },
  appMenuAddBtn: {
    float: 'right',
    marginLeft: '5px'
  },
  mb30: {
    margin: '15px 0 0 0',
    height: '1px',
    backgroundColor: '#ccc',
    border: 'none'
  },
  tableHeader: {
    fontSize: '18px',
    fontWeight: 'bold'
  },

  loadingWrap: {
    textAlign: 'center',
    width: '100%',
    padding: '10px 0'
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function WebViewAppManage(props) {
  const firebase = useContext(FirebaseContext);
  const {eventInfo,} = props;
  const query = useQuery();
  const menuKey = query.get('key');
  const history = useHistory();
  const classes = useStyles();
  const {setShowToast, renderToast} = useToast({
    message: '정상적으로 처리되었습니다.', onClose: () => console.log('onClose')
  })
  const [isLoading, setIsLoading] = useState(true);
  const [menuTitle, setMenuTitle] = useState('');
  const [urlInfo, setUrlInfo] = useState('');

  useEffect(() => {
    if (!eventInfo.menus[menuKey]) {
      history.push(DashBoard)
    } else {
      const unsubscribe = firebase.firestore().collection('Events')
        .doc(eventInfo.id)
        .collection('WebViews')
        .where('menuId', '==', menuKey)
        .onSnapshot((docResult) => {
          const resultData = [];
          docResult.forEach((doc) => {
            resultData.push(doc.data())
          })
          setUrlInfo(resultData[0])
          setMenuTitle(eventInfo.menus[menuKey].title);
          setIsLoading(false)
        })
      return () => {
        unsubscribe();
      }
    }
  }, [])

  const validationSchema = Yup.object().shape({
    url: Yup.string()
      .max(500, '500자 이하로 입력해주세요.')
      .required('URL을 입력해주세요.')
      .url('URL 형식으로 입력해주세요.')
  })
  const onSubmit = (value) => {
    console.log('value', value);
    if (urlInfo && urlInfo.url) {
      firebase.firestore().collection('Events')
        .doc(eventInfo.id)
        .collection('WebViews')
        .doc(urlInfo.id)
        .update({
          url: value.url
        }).then(() => {
        setShowToast(true);
      })
    } else {
      const newDocRef = firebase.firestore().collection('Events')
        .doc(eventInfo.id)
        .collection('WebViews')
        .doc();
      newDocRef.set({
        id: newDocRef.id,
        createdAt: new Date(),
        eventId: eventInfo.id,
        menuId: menuKey,
        url: value.url,
        style: {},
      }).then(() => {
        setShowToast(true);
      })
    }


  }

  return !isLoading ?
    (
      <>
        {renderToast()}
        <CssBaseline/>
        <Container maxWidth="lg" style={{overflow: 'auto'}}>
          <Typography variant="h5" className={classes.eventInfoTitle}>
            <AppMenuTitleEditable
              eventInfo={eventInfo}
              appMenuTitle={menuTitle}
              appMenuKey={menuKey}
            />

          </Typography>
          <hr className={classes.mb30}/>

          <Formik
            initialValues={{url: urlInfo && urlInfo.url ? urlInfo.url : ''}}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {
              () => (
                <Form>
                  <Field
                    disabled={false}
                    component={TextField}
                    variant="outlined"
                    name="url"
                    fullWidth
                    margin="normal"
                    label="URL(http:// 포함)"
                    placeholder="http://를 포함해주세요."
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    margin="normal"
                    color="primary"
                    style={{float: 'right'}}
                  >
                    {
                      urlInfo && urlInfo.url
                        ? (<>수정하기</>)
                        : (<>저장하기</>)
                    }

                  </Button>
                </Form>
              )
            }
          </Formik>
        </Container>
      </>
    )
    : (
      <div className={classes.loadingWrap}>
        <CircularProgress/>
      </div>
    )

}
