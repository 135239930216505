import React, { useContext, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FirebaseContext from '../../../../Context/Firebase';
import { useFormik } from 'formik';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { Editor } from '@tinymce/tinymce-react';
import { tinyApiKey } from '../../../../Constant/SiteConfig';
import AddBoxIcon from '@material-ui/icons/AddBox';
import OutsideLinkAddForm from './OutsideLinkAddForm';
import OutsideLinkList from './OutsideLinkList';
import FileUploadAddForm from './FileUploadAddForm';
import {
  RandomKey,
  getRandomKey,
  getExtensionOfFilename,
  preventEnterKeySubmit,
  convertArrayToObject,
} from '../../../../Utill/Functions';
import * as yup from 'yup';
import FileUploadList from './FileUploadList';
import LoadingOverlay from '../../../../Component/LoadingOverlay/Index';

const useStyles = makeStyles(() => ({
  itemWrap: {
    padding: '10px',
    width: '180px',
    cursor: 'pointer',
    height: '180px',
  },
  loadingWrap: {
    textAlign: 'center',
    width: '100%',
    padding: '10px 0',
  },
  item: {
    border: '1px solid #ccc',
    borderRadius: '10px',
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
  appIconWrap: {
    textAlign: 'center',
  },
  appIcon: {
    textAlign: 'center',
    fontSize: '30px',
    marginTop: '30px',
  },
  plusIcon: {
    marginTop: '15px',
    paddingTop: '10px',
    borderTop: '1px solid #ccc',
  },
  modalContentWrap: {
    minWidth: '300px',
    width: '90vw',
    maxWidth: '550px',
  },
  modalTitle: {
    padding: '5px 10px',
    margin: '0 auto 10px auto',
    borderBottom: '1px solid #ccc',
  },
  appDescription: {
    fontSize: '12px',
    color: '#8e8e8e',
  },
  appTitle: {
    fontSize: '17px',
  },
  errMsg: {
    padding: '5px 0',
    color: '#f53838',
  },
  onlineEventCheck: {
    paddingLeft: '20px',
    paddingTop: '7px',
  },
  addSectionTitle: {
    borderBottom: '1px solid #ccc',
    display: 'flex',
    justifyContent: 'space-between',
  },
  fontBold: {
    fontWeight: 'bold',
  },
}));
const outsideLinkSchema = yup.object().shape({
  title: yup
    .string()
    .required('링크명을 입력해주세요.')
    .max(20, '링크명은 20자이내로 입력해주세요'),
  url: yup
    .string()
    .required('URL을 입력해주세요.')
    .max(200, 'URL 주소는 200자이내로 입력해주세요.')
    .url('URL형식으로 입력해주세요.'),
});
const fileUploadSchema = yup.object().shape({
  fileTitle: yup
    .string()
    .required('파일명을 입력해주세요')
    .max(20, '파일명은 20자이내로 입력해주세요'),
  selectedFile: yup.mixed().test('isUploaded', '파일을 업로드해주세요', function isUploaded(value) {
    return value && value.name !== '';
  }),
});

export default function AgendaManageModal(props) {
  const { menuId, menuDatas, itemInfo, isModal, closeModalWithoutConfirm, eventInfo } = props;
  const [isLoading, setIsLoading] = useState(false);
  const firebase = useContext(FirebaseContext);
  const classes = useStyles();
  const sessionStartAt = itemInfo ?
    moment(itemInfo.startAt.toDate()) : menuDatas && menuDatas.length > 0 ?
      moment(menuDatas[menuDatas.length - 1].endAt.toDate()) : moment(eventInfo.startAt.toDate());
  const sessionEndAt = itemInfo ?
    moment(itemInfo.endAt.toDate()) : menuDatas && menuDatas.length > 0 ?
      moment(menuDatas[menuDatas.length - 1].endAt.toDate()).add(10, 'minutes') :
      moment(eventInfo.startAt.toDate()).add(10, 'minutes');

  useEffect(() => {
    if (itemInfo) {
      setOutsideLinkObject({ ...itemInfo.links });
      setUploadFileObject({ ...itemInfo.documents });
    } else {
    }
  }, []);

  //외부링크 초기값관련 시작
  const [isOutsideLinkForm, setIsOutsideLinkForm] = useState(false);
  const [outsideLinkItem, setOutsideLinkItem] = useState({ title: '', url: '' });
  const [outsideLinkObject, setOutsideLinkObject] = useState(null);
  const [isOutsideLinkEditMode, setIsOutsideLinkEditMode] = useState(false);
  const { title, url } = outsideLinkItem;
  //외부링크 초기값관련 끝

  //외부링크 관련 함수 시작
  const openOutsideLinkInsertForm = () => {
    setIsOutsideLinkForm(true);
  };
  const closeOutsideLinkInsertForm = () => {
    setIsOutsideLinkForm(false);
    setOutsideLinkItem({ title: '', url: '' });
    setIsOutsideLinkEditMode(false);
  };
  const outsideLinkOnChange = (e) => {
    const { name, value } = e.target;
    setOutsideLinkItem({
      ...outsideLinkItem,
      [name]: value,
    });
  };
  const outsideLinkOnCreate = () => {
    outsideLinkSchema
      .isValid({
        title,
        url,
      })
      .then((valid) => {
        if (valid) {
          const randomId = RandomKey(4);
          setOutsideLinkObject({
            ...outsideLinkObject,
            [randomId]: {
              id: randomId,
              title,
              url,
            },
          });
          setOutsideLinkItem({ title: '', url: '' });
          setIsOutsideLinkForm(false);
        } else {
          outsideLinkSchema.validate({ title, url }).catch((r) => {
            alert(r.message);
          });
        }
      });
  };
  const outsideLinkOnRemove = (targetId) => {
    delete outsideLinkObject[targetId];
    setOutsideLinkObject({ ...outsideLinkObject });
  };
  const outsideLinkOnEdit = (targetValues) => {
    setIsOutsideLinkEditMode(true);
    setIsOutsideLinkForm(true);
    setOutsideLinkItem({
      id: targetValues.id,
      title: targetValues.title,
      url: targetValues.url,
    });
  };
  const onEditComplete = () => {
    outsideLinkSchema
      .isValid({
        title,
        url,
      })
      .then((valid) => {
        if (valid) {
          setOutsideLinkObject({
            ...outsideLinkObject,
            [outsideLinkItem.id]: {
              ...outsideLinkItem,
            },
          });
          closeOutsideLinkInsertForm();
        } else {
          outsideLinkSchema.validate({ title, url }).catch((r) => {
            alert(r.message);
          });
        }
      });
  };
  //외부링크 관련 함수 끝

  //파일첨부 초기값 관련 시작
  const [uploadFileObject, setUploadFileObject] = useState(null);
  const [isFileUploadForm, setIsFileUploadForm] = useState(false);
  const [fileTitle, setFileTitle] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [inEditModeDeletedFiles, setInEditModeDeletedFiles] = useState([]);
  //파일첨부 초기값 관련 끝

  //파일첨부 관련 함수 시작
  const openFileUploadAddForm = () => {
    setIsFileUploadForm(true);
  };
  const closeFileUploadAddForm = () => {
    setIsFileUploadForm(false);
  };

  const changeUploadFileTitle = (e) => {
    setFileTitle(e.target.value);
  };
  const fileUploadOnCreate = () => {
    fileUploadSchema
      .isValid({
        fileTitle,
        selectedFile,
      })
      .then((valid) => {
        if (valid) {
          const randomId = RandomKey(5);
          setUploadFileObject({
            ...uploadFileObject,
            [randomId]: {
              id: randomId,
              title: fileTitle,
              path: '',
              extension: getExtensionOfFilename(selectedFile.name),
              selectedFile: selectedFile,
            },
          });

          setFileTitle('');
          setSelectedFile(null);
          setIsFileUploadForm(false);
        } else {
          fileUploadSchema.validate({ fileTitle, selectedFile }).catch((r) => {
            alert(r.message);
          });
        }
      });
  };
  const fileUploadOnRemove = (targetFileItemInfo) => {
    if (itemInfo) {
      setInEditModeDeletedFiles((prevState) => [...prevState, targetFileItemInfo.path]);
    }
    const removedTargetItemList = Object.values(uploadFileObject).filter((item) => {
      return item.id !== targetFileItemInfo.id;
    });
    const newUploadFileObj = convertArrayToObject(removedTargetItemList, 'id');
    setUploadFileObject(newUploadFileObj);
  };

  const fileUploadHandler = (nowUploadedFiles, docId) => {
    let documentDatas;
    return new Promise((totalResolve) => {
      Promise.all(
        Object.values(nowUploadedFiles).map((objItem) => {
          if (objItem.selectedFile) {
            const RandomText = getRandomKey();
            const storageRef = firebase.storage().ref(`documents/${docId}_${RandomText}`);
            documentDatas = {
              ...documentDatas,
              [objItem.id]: `documents/${docId}_${RandomText}`,
            };
            return storageRef.put(objItem.selectedFile);
          }
        }),
      )
        .then(() => {
          totalResolve(documentDatas);
        })
        .catch((error) => {
          //실패한 파일 삭제로
          console.log(`Some failed: `, error.message);
        });
    });
  };
  //파일첨부 관련 함수 끝

  const onSubmit = async (values, { setSubmitting }) => {
    setIsLoading(true);

    const newDocRef = firebase.firestore().collection('Events')
      .doc(eventInfo.id)
      .collection('Agendas')
      .doc();

    const { startAt, endAt } = values;
    let insertDocumentList = {};
    if (uploadFileObject) {
      if (itemInfo) {
        const needUploadFilesPath = await fileUploadHandler(uploadFileObject, itemInfo.id);
        Object.values(uploadFileObject).map((item) => {
          insertDocumentList = {
            ...insertDocumentList,
            [item.id]: {
              id: item.id,
              title: item.title,
              path: item.path ? item.path : needUploadFilesPath[item.id],
              extension: item.extension,
            },
          };
        });
      } else {
        const uploadedFilePaths = await fileUploadHandler(uploadFileObject, newDocRef.id);
        Object.values(uploadFileObject).map((item) => {
          insertDocumentList = {
            ...insertDocumentList,
            [item.id]: {
              id: item.id,
              title: item.title,
              path: uploadedFilePaths[item.id],
              extension: item.extension,
            },
          };
        });
      }
    }

    inEditModeDeletedFiles.length > 0 && inEditModeDeletedFiles.map((deleteItemPath) => {
      if (deleteItemPath) {
        firebase.storage().ref(deleteItemPath).delete();
      }
    })
    const convertedStartAt = new Date(startAt.toDate().getFullYear(), startAt.toDate().getMonth(),
      startAt.toDate().getDate(), startAt.toDate().getHours(), startAt.toDate().getMinutes()
    )
    const convertedEndAt = endAt ? new Date(endAt.toDate().getFullYear(), endAt.toDate().getMonth(),
      endAt.toDate().getDate(), endAt.toDate().getHours(), endAt.toDate().getMinutes()
    ) : null;

    if (itemInfo) {
      firebase
        .firestore()
        .collection('Events')
        .doc(eventInfo.id)
        .collection('Agendas')
        .doc(itemInfo.id)
        .update({
          title: values.title || '',
          startAt: convertedStartAt || null,
          endAt: convertedEndAt || null,
          place: values.place || '',
          isOnlineEvent: values.isOnlineEvent || false,
          description: values.description || '',
          links: {
            ...outsideLinkObject,
          },
          documents: {
            ...insertDocumentList,
          },
        })
        .then(() => {
          setSubmitting(false);
          setIsLoading(false);
          closeModalWithoutConfirm();
        })
        .catch((error) => {
          setSubmitting(false);
          setIsLoading(false);
          console.error(error);
        });
    } else {
      newDocRef
        .set({
          id: newDocRef.id,
          eventId: eventInfo.id,
          title: values.title || '',
          startAt: convertedStartAt || null,
          endAt: convertedEndAt || null,
          place: values.place || '',
          menuId: menuId,
          isOnlineEvent: values.isOnlineEvent || false,
          description: values.description || '',
          createdAt: new Date(),
          links: {
            ...outsideLinkObject,
          },
          documents: {
            ...insertDocumentList,
          },
        })
        .then(() => {
          setSubmitting(false);
          setIsLoading(false);
          closeModalWithoutConfirm();
        })
        .catch((error) => {
          setSubmitting(false);
          setIsLoading(false);
          console.error(error);
        });
    }
  };

  const {
    values,
    setFieldValue,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      title: itemInfo ? itemInfo.title : '',
      startAt: sessionStartAt,
      endAt: sessionEndAt,
      place: itemInfo ? itemInfo.place : '',
      isOnlineEvent: itemInfo ? itemInfo.isOnlineEvent : false,
      description: itemInfo ? itemInfo.description : '',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('세션명을 입력해주세요.').max(50, '50자 이내로 입력해주세요.'),
      startAt: Yup.mixed().test(
        'isBefore',
        '시작시각은 종료시각 이전으로 선택해주세요.',
        function isBefore(value) {
          if (!this.parent.endAt) {
            return true;
          }
          return value.isSameOrBefore(this.parent.endAt, 'minute');
        },
      ),
      endAt: Yup.mixed().test(
        'isAfter',
        '종료시각은 시작시간 이후로 선택해주세요.',
        function isAfter(value) {
          if (!value) {
            return true;
          }
          return value.isSameOrAfter(this.parent.startAt, 'minute');
        },
      ),
    }),
    onSubmit,
  });
  const setEndAtToStartAt = (value) => {
    values.endAt.set({
      year: value.get('year'),
      month: value.get('month'),
      date: value.get('date'),
    });
    setFieldValue('startAt', value);
    setFieldValue('endAt', values.endAt);
  };

  return (
    <>
      <Dialog
        open={isModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className={classes.modalTitle}>
            <Typography variant="h5" className={classes.eventInfoTitle}>
              세션추가
            </Typography>
          </div>
          <div className={classes.modalContentWrap}>
            <form onSubmit={handleSubmit} onKeyDown={preventEnterKeySubmit}>
              <Box mt={2}>
                <TextField
                  name="title"
                  type="text"
                  onChange={handleChange}
                  value={values.title}
                  label="세션명"
                  variant="outlined"
                  fullWidth
                />
                <p className={classes.errMsg}>{errors.title && touched.title && errors.title}</p>
              </Box>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="ko">
                <>
                  <Grid>
                    <Box mt={2}>
                      <DatePicker
                        disableToolbar
                        variant="dialog"
                        format="YYYY. MM. DD(dd)"
                        id="date-picker-from"
                        label="날짜"
                        name="startAt"
                        value={values.startAt}
                        onChange={(value) => setEndAtToStartAt(value)}
                        fullWidth
                        inputVariant="outlined"
                      />
                    </Box>
                  </Grid>
                  <Grid>
                    <Box mt={2}>
                      <TimePicker
                        label="시작시각"
                        mask="__:__ _M"
                        value={values.startAt}
                        onChange={(value) => setFieldValue('startAt', value)}
                        inputVariant="outlined"
                        fullWidth
                      />
                      {errors.startAt && <p className={classes.errMsg}>{errors.startAt}</p>}
                    </Box>
                  </Grid>

                  <Grid>
                    <Box mt={2}>
                      <TimePicker
                        label="종료시각"
                        mask="__:__ _M"
                        value={values.endAt}
                        onChange={(value) => setFieldValue('endAt', value)}
                        inputVariant="outlined"
                        fullWidth
                      />
                      {errors.endAt && <p className={classes.errMsg}>{errors.endAt}</p>}
                    </Box>
                  </Grid>
                </>
              </MuiPickersUtilsProvider>
              <Box mt={2}>
                <Grid container>
                  <Grid>
                    <TextField
                      label="장소명"
                      name="place"
                      variant="outlined"
                      onChange={handleChange}
                      value={values.place}
                    />
                  </Grid>
                  <Grid className={classes.onlineEventCheck}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          name="isOnlineEvent"
                          checked={values.isOnlineEvent}
                          color="primary"
                        />
                      }
                      label="온라인 세션"
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mt={2}>
                <Typography variant="subtitle1">내용 입력</Typography>
                <Editor
                  branding={false}
                  apiKey={tinyApiKey}
                  init={{
                    height: 400,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image',
                      'charmap print preview anchor help',
                      'searchreplace visualblocks code',
                      'insertdatetime media table paste wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | bold forecolor backcolor italic | \
                      alignleft aligncenter alignright | \
                      bullist numlist outdent indent',
                    block_formats: '본문=p; 제목 1=h1; 제목 2=h2; 제목 3=h3; 제목 4=h4; 제목 5=h5; 제목 6=h6;'
                  }}
                  value={values.description}
                  onEditorChange={(value) => setFieldValue('description', value)}
                />
              </Box>

              <Box mt={3}>
                <div className={classes.addSectionTitle}>
                  <div>
                    <Typography variant="subtitle1" className={classes.fontBold}>
                      외부링크
                    </Typography>
                  </div>
                  <div>
                    <AddBoxIcon
                      style={{ cursor: 'pointer' }}
                      fontSize="large"
                      onClick={openOutsideLinkInsertForm}
                    />
                  </div>
                </div>
              </Box>

              {outsideLinkObject ? (
                <OutsideLinkList
                  outsideLinkObject={outsideLinkObject}
                  onRemove={outsideLinkOnRemove}
                  onEdit={outsideLinkOnEdit}
                />
              ) : (
                ''
              )}
              {isOutsideLinkForm ? (
                <OutsideLinkAddForm
                  title={title}
                  url={url}
                  closeOutsideLinkInsertForm={closeOutsideLinkInsertForm}
                  onChange={outsideLinkOnChange}
                  onCreate={outsideLinkOnCreate}
                  isOutsideLinkEditMode={isOutsideLinkEditMode}
                  onEditComplete={onEditComplete}
                />
              ) : (
                ''
              )}

              <Box mt={5}>
                <div className={classes.addSectionTitle}>
                  <div>
                    <Typography variant="subtitle1" className={classes.fontBold}>
                      첨부파일
                    </Typography>
                  </div>
                  <div>
                    <AddBoxIcon
                      style={{ cursor: 'pointer' }}
                      fontSize="large"
                      onClick={openFileUploadAddForm}
                    />
                  </div>
                </div>
              </Box>

              {uploadFileObject ? (
                <FileUploadList
                  uploadFileObject={uploadFileObject}
                  fileUploadOnRemove={fileUploadOnRemove}
                />
              ) : (
                ''
              )}

              {isFileUploadForm && (
                <FileUploadAddForm
                  fileTitle={fileTitle}
                  setSelectedFile={setSelectedFile}
                  closeFileUploadAddForm={closeFileUploadAddForm}
                  changeUploadFileTitle={changeUploadFileTitle}
                  fileUploadOnCreate={fileUploadOnCreate}
                />
              )}

              <div style={{ textAlign: 'right', padding: '20px 0' }}>
                <div>
                  <p className={classes.errMsg}>{errors.title && touched.title && errors.title}</p>
                  {errors.startAt && <p className={classes.errMsg}>{errors.startAt}</p>}
                  {errors.endAt && <p className={classes.errMsg}>{errors.endAt}</p>}
                </div>
                <Button
                  type="button"
                  variant="contained"
                  color="default"
                  className={classes.submit}
                  size="large"
                  style={{ marginRight: '10px' }}
                  onClick={() => closeModalWithoutConfirm()}
                >
                  취소
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  size="large"
                  disabled={isSubmitting}
                >
                  {itemInfo ? <span>수정</span> : <span>등록</span>}
                </Button>
              </div>
            </form>
          </div>
        </DialogContent>
        {isLoading ? <LoadingOverlay /> : ''}
      </Dialog>
    </>
  );
}
