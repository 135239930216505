// export const siteDomain = 'https://artistmatching-3f32f.web.app/';
// export const siteDomain = 'https://www.icontact.kr';

// export const functionUrl = 'http://localhost:5001/nollamice/us-central1';
export const functionUrl = 'https://us-central1-nollamice.cloudfunctions.net';

export const tinyApiKey = 'z8ef8ktucl0qwn94c1j6q5iu2hus0sj09eejlzxe8oov15gi';

export const eventState = {
  1: '편집중',
  2: '게시중',
}

export const appMenuList = {
  1: {
    icon: 'calendar',
    title: '프로그램',
    type: 'AGENDA',
    //collection - Agendas
  },
  2: {
    icon: 'people',
    title: '참가자',
    type: 'ATTENDEE',
    //collection - Attendees
  },
  3: {
    icon: 'message-square',
    title: '커뮤니티',
    type: 'COMMUNITY'
    //collection - Posts
  },
  4: {
    icon: 'list',
    title: '웹 링크',
    type: 'WEB_LIST',
    //collection - WebLinks
  },
  5: {
    icon: 'file',
    title: '파일리스트',
    type: 'FILE_LIST'
  },
  6: {
    icon: 'info',
    title: '행사개요',
    type: 'INFO',
  },
  7: {
    icon: 'mic',
    title: '인물리스트',
    type: 'PERSON_LIST',
  },
  8: {
    icon: 'file-text',
    title: '기관리스트',
    type: 'ORGANIZATION_LIST',
  },
  9: {
    icon: 'browser',
    title: '외부 사이트',
    type: 'WEB_VIEW'
    //collection - WebViews
  }
}

export const appCollectionTitle = {
  'AGENDA': 'Agendas',
  'WEB_VIEW' : 'WebViews',
  'WEB_LIST': 'WebLinks',
  'COMMUNITY' :'Posts'
}

export const allowedProgramFileTypes = ["DOC", "DOT", "WBK", "DOCX", "DOCM", "DOTX", "DOTM", "DOCB", "XLS", "XLT", "XLM", "XLSX", "XLSM", "XLTX", "XLTM", "XLSB", "XLA", "XLAM", "XLL", "XLW", "PPT", "POT", "PPS", "PPTX", "PPTM", "POTX", "POTM", "PPAM", "PPSX", "SLDX", "SLDM", "PDF", "JPEG", "JPG", "PNG"];
export const allowedWordFileTypes = ["DOC", "DOT", "WBK", "DOCX", "DOCM", "DOTX", "DOTM", "DOCB"];
export const allowedExcelFileTypes = ["XLS", "XLT", "XLM", "XLSX", "XLSM", "XLTX", "XLTM", "XLSB", "XLA", "XLAM", "XLL", "XLW"];
export const allowedPptFileTypes = ["PPT", "POT", "PPS", "PPTX", "PPTM", "POTX", "POTM", "PPAM", "PPSX", "SLDX", "SLDM"];
export const allowedPdfFileTypes = ["PDF"];
export const allowedJpgFileTypes = ["JPG", "JPEG"];
export const allowedPngFileTypes = ["PNG"];


export const tableSetLable = {
  body: {
    emptyDataSourceMessage: '관련 데이터가 없습니다.',
  },
  toolbar: {
    searchTooltip: '검색',
    searchPlaceholder: '검색',
  },
  pagination: {
    labelDisplayedRows: '현재: {from} - {to} / 전체: {count}',
    labelRowsSelect: '줄 씩 보기',
    firstAriaLabel: '첫 페이지',
    firstTooltip: '첫 페이지',
    previousAriaLabel: '이전 페이지',
    previousTooltip: '이전 페이지',
    nextAriaLabel: '다음 페이지',
    nextTooltip: '다음 페이지',
    lastAriaLabel: '마지막 페이지',
    lastTooltip: '마지막 페이지',
  },
};
