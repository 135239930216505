import React, {useContext, useRef, useState} from 'react';
import ContentEditable from "react-contenteditable";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import CreateIcon from "@material-ui/icons/Create";
import FirebaseContext from "../../Context/Firebase";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },

  appMenuTitleIcon: {
    position: 'relative',
    top: '3px',
    left: '5px',
    cursor: 'pointer'
  },
  editableInput: {
    width: '50%',
    padding: '5px 10px',
    display: 'inline'
  }

}));


export default function AppMenuTitleEditable(props) {
  const {eventInfo, appMenuTitle, appMenuKey} = props;
  const classes = useStyles();
  const textInput = useRef(null);
  const editableText = useRef('');
  const firebase = useContext(FirebaseContext);
  const [appTitleIsEditing, setAppTitleIsEditing] = useState(false)
  const [appTitleText, setAppTitleText] = useState(appMenuTitle)

  const noticeInputHandleChange = evt => {
      editableText.current = evt.target.value;
  };


  const changeEditableInput = () => {
    if (textInput.current) {
      textInput.current.focus()
    }
  }

  const cancelTitleEdit = () => {
    editableText.current = appTitleText;
    setAppTitleIsEditing(false)
    setAppTitleText(appTitleText)
  }

  const noticeInputOnFocus = () => {
    setAppTitleIsEditing(true)
  }

  const completeTitleEdit = () => {
    setAppTitleIsEditing(false)
    if (!editableText.current) {
      setAppTitleText(appTitleText)
    } else {
      setAppTitleText(editableText.current)
      firebase.firestore().doc(`Events/${eventInfo.id}`)
        .update({
          [`menus.${appMenuKey}.title`]: editableText.current
        })
    }
  }
  return (
    <>
      <ContentEditable
        innerRef={textInput}
        html={appTitleText}
        onFocus={noticeInputOnFocus}
        onKeyDown={e => e.keyCode === 13 && e.preventDefault()}
        className={classes.editableInput}
        onChange={noticeInputHandleChange}
      />
      {
        appTitleIsEditing
          ? (
            <>
              <CloseIcon className={classes.appMenuTitleIcon} onClick={cancelTitleEdit}/>
              <DoneIcon className={classes.appMenuTitleIcon} onClick={completeTitleEdit}/>
            </>
          )
          : (<CreateIcon className={classes.appMenuTitleIcon} onClick={changeEditableInput}/>)
      }

    </>
  )
}
