import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { Field, Form, Formik } from 'formik';
import Typography from '@material-ui/core/Typography';
import { TextField } from 'formik-material-ui';

import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';
import * as Functions from '../../../Utill/Functions';
import * as Yup from 'yup';
import FirebaseContext from '../../../Context/Firebase';
import LoadingOverlay from '../../../Component/LoadingOverlay/Index';

const useStyles = makeStyles(() => ({
  button: {
    float: 'right',
    padding: '10px 50px',
  },
  eventStyleDescription: {
    fontSize: '13px',
    color: '#716a61',
    textIndent: '14px',
    marginTop: '5px',
    display: 'block',
  },
  mt30: {
    marginTop: '30px',
  },
  eventStyleWrap: {
    padding: '15px',
    border: '1px solid #ccc',
    marginTop: '15px',
  },
}));

export default function EventBasicInfoProperty(props) {
  const { eventInfo } = props;
  const classes = useStyles();
  const [isEntrance, setIsEntrance] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const firebase = useContext(FirebaseContext);

  useEffect(() => {
    if (eventInfo.passcode && eventInfo.passcode.length > 0) {
      setIsEntrance(true);
    }
  }, []);

  const validationSchema = Yup.object().shape({
    passcode: Yup.string()
      .min(1, '1자 이상 입력해주세요.')
      .max(10, '10자 이하로 입력해주세요.')
      .test('type', '입장코드는 숫자와 영문 조합으로 입력해주세요.', (value) =>
        Functions.passcodeType.test(value),
      ),
  });
  const formValues = {
    eventStyle: !eventInfo.passcode || eventInfo.passcode === '' ? 'public' : 'private',
    passcode: eventInfo.passcode || '',
  };

  const changeEventStyle = (setFieldValue, value) => {
    setFieldValue('eventStyle', value);
    if (value === 'private') {
      setFieldValue('passcode', eventInfo.passcode || '');
    } else {
      setFieldValue('passcode', '');
    }
  };
  const onSubmit = (values) => {
    setIsLoading(true);
    const { passcode } = values;

    if (values.eventStyle === 'private') {
      if (values.passcode === '') {
        alert('입장코드를 입력해주세요.');
        setIsLoading(false);
        return false;
      }
    }

    firebase
      .firestore()
      .collection('Events')
      .doc(eventInfo.id)
      .update({
        passcode,
      })
      .then(() => {
        setIsLoading(false);
        window.scrollTo(0, 0);
      });
  };

  return (
    <>
      <Box mt={1} mb={1}>
        <Formik initialValues={formValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ values, setFieldValue }) => (
            <Form>
              <div>
                <Typography variant="subtitle1" className={classes.mt30}>
                  행사 속성
                </Typography>
                <div className={classes.eventStyleWrap}>
                  <Typography variant="body2">
                    <label>
                      <input
                        type="radio"
                        name="eventStyle"
                        value="public"
                        checked={values.eventStyle === 'public'}
                        onChange={() => changeEventStyle(setFieldValue, 'public')}
                      />
                      공개행사
                    </label>
                    <span className={classes.eventStyleDescription}>
                      행사 리스트에 게시가 되며, 누구나 참여할 수 있습니다.
                    </span>
                  </Typography>
                  <br />
                  <Typography variant="body2">
                    <label>
                      <input
                        type="radio"
                        name="eventStyle"
                        value="private"
                        checked={values.eventStyle === 'private'}
                        onChange={() => changeEventStyle(setFieldValue, 'private')}
                      />
                      입장코드 행사
                    </label>
                    <span className={classes.eventStyleDescription}>
                      {' '}
                      행사 리스트에 게시가 되며, 입장코드를 입력해야 참여할 수 있습니다.{' '}
                    </span>
                  </Typography>
                  {values.eventStyle === 'private' && (
                    <Field
                      disabled={false}
                      component={TextField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      placeholder="행사참석시 입력해야할 행사코드를 입력해주세요."
                      name="passcode"
                    />
                  )}
                </div>
              </div>

              <Box mt={4}>
                <Grid container>
                  <Grid item xs={12} md={12} lg={12} className={classes.progressBtn}>
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      저장
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      {isLoading ? <LoadingOverlay /> : ''}
    </>
  );
}
