import * as firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import React, {useState, useEffect} from 'react';
import {
  BrowserRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import AuthenticationContext from './Context/Authentication';
import FirebaseContext from './Context/Firebase';
import * as Routes from './Constant/Routes';
import Header from './Component/Header/Index';
import Footer from './Component/Footer/Index';
import Index from './Screen/Index/Index';
import SignUp from './Screen/SignUp/Index';
import DashBoard from './Screen/DashBoard/Index';
import EventDetail from './Screen/EventDetail/Index';
import ChangePassword from './Screen/Membership/ChangePassword';
import MemberInfoModify from './Screen/Membership/MemberInfoModify';
import CreateEvent from './Screen/CreateEvent/Index';
import Withdrawal from './Screen/Membership/Withdrawal';
import Terms from './Screen/Terms/Index';
import Test from './Screen/Test/Index';


const firebaseConfig = {
  apiKey: "AIzaSyDyLzTqHE5x16NK8moz0nvyTeO12d8_21U",
  authDomain: "nollamice.firebaseapp.com",
  databaseURL: "https://nollamice.firebaseio.com",
  projectId: "nollamice",
  storageBucket: "nollamice.appspot.com",
  messagingSenderId: "833631050351",
  appId: "1:833631050351:web:afe2f50aa59b436a274b9a",
  measurementId: "G-KJ2T6C4G07"
};

firebase.initializeApp(firebaseConfig);
// for development enviroment
// const IS_DEVELOPING = process.env.NODE_ENV === 'development';
// if (IS_DEVELOPING) {
//   console.log('Happy coding! Developer👋');
//   firebase.functions().useFunctionsEmulator('http://localhost:5001');
//   console.log('connected emulator:functions');
//   firebase.firestore().settings({
//     host: 'localhost:8080',
//     ssl: false,
//   });
//   console.log('connected emulator:firestore');
// }
//


export default function App() {
  const [authUser, setAuthUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        if (currentUser.email) {
          setAuthUser(currentUser.email);
          setIsLoading(false);
        }
      } else {
        setAuthUser(null);
        setIsLoading(false);
      }
    });
  }, []);

  const ClientRoute = ({children, ...rest}) => (
    <Route
      {...rest}
      render={({location}) => (!firebase.auth().currentUser
          ? children
          : (<Redirect
            to={{
              pathname: Routes.DashBoard,
              state: {from: location,}
            }}
          />)
      )}
    />
  );


  const UserRoute = ({children, ...rest}) => (
    <Route
      {...rest}
      render={({location}) => (firebase.auth().currentUser
          ? children
          : (<Redirect
            to={{
              pathname: Routes.Root,
              state: {from: location,}
            }}
          />)
      )}
    />
  );

  return isLoading ? (
      <div style={{padding: '10px'}}>
        잠시만 기다려주세요......
      </div>
    )
    : (
      <FirebaseContext.Provider value={firebase}>
        <AuthenticationContext.Provider value={authUser}>
          <BrowserRouter>
            <div style={{maxWidth: '1920px', margin: '0 auto', backgroundColor:'#fff'}}>
              {authUser && <Header/>}
              <Switch>
                <ClientRoute exact path={Routes.Root}>
                  <Index/>
                </ClientRoute>

                <ClientRoute exact path={Routes.SignUp}>
                  <SignUp/>
                </ClientRoute>

                <UserRoute exact path={Routes.DashBoard}>
                  <DashBoard/>
                </UserRoute>

                <UserRoute path={`${Routes.DashBoard}/:eventId`}>
                  <EventDetail/>
                </UserRoute>

                <UserRoute exact path={Routes.ChangePassword}>
                  <ChangePassword/>
                </UserRoute>

                <UserRoute exact path={Routes.MemberInfoModify}>
                  <MemberInfoModify/>
                </UserRoute>

                <UserRoute exact path={Routes.CreateEvent}>
                  <CreateEvent/>
                </UserRoute>


                <UserRoute exact path={Routes.Withdrawal}>
                  <Withdrawal/>
                </UserRoute>

                <Route exact path={Routes.Terms}>
                  <Terms/>
                </Route>

                <Route exact path={Routes.Test}>
                  <Test/>
                </Route>

                <Redirect to="/"/>
                <Route path="*">
                  404page
                </Route>
              </Switch>
              {/* {authUser && <Footer/>} */}
            </div>
          </BrowserRouter>
        </AuthenticationContext.Provider>
      </FirebaseContext.Provider>
    )
}

