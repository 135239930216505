import React, {useState, useEffect, useContext,} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  useHistory,
  useLocation
} from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from "@material-ui/core/Button";

import NoticeManageModal from "./NoticeManageModal";
import FirebaseContext from "../../../../Context/Firebase";
import AuthenticationContext from "../../../../Context/Authentication";
import CircularProgress from "@material-ui/core/CircularProgress";
import {printDateTime} from '../../../../Utill/Functions';
import CheckIcon from '@material-ui/icons/Check';

import AppMenuTitleEditable from "../../../../Component/AppMenuTitleEditable/Index";
import useToast from "../../../../Hook/useToast";
import {DashBoard} from "../../../../Constant/Routes";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
  eventInfoTitle: {
    paddingTop: '10px'
  },
  appMenuAddBtn: {
    float: 'right'
  },
  mb30: {
    margin: '15px 0 30px 0',
    height: '1px',
    backgroundColor: '#ccc',
    border: 'none'
  },
  tableHeader: {
    fontSize: '18px',
    fontWeight: 'bold'
  },
  noticeMangeBtn: {
    padding: '10px 20px',
    marginRight: '5px',
    background: '#fff',
    border: '1px solid #ccc',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  loadingWrap: {
    textAlign: 'center',
    width: '100%',
    padding: '10px 0'
  },

}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


export default function NoticeAppManage(props) {
  const {eventInfo} = props;
  const query = useQuery();
  const menuKey = query.get('key');
  const history = useHistory();
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthenticationContext)
  const [isNoticeAddModal, setIsNoticeAddModal] = useState(false)
  const [noticeData, setNoticeData] = useState([])
  const [noticeInfo, setNoticeInfo] = useState(null)
  const [isLoading, setIsLoading] = useState(true);
  const [menuTitle, setMenuTitle] = useState('');
  const {setShowToast, renderToast} = useToast({message: '해당 공지사항이 삭제되었습니다.', onClose: () => console.log('onClose')})


  useEffect(() => {
    setIsLoading(true);
    eventInfo.menus[menuKey] === undefined ? history.push(DashBoard) : setMenuTitle(eventInfo.menus[menuKey].title);
    const unsubscribe = firebase.firestore().collection('Events')
      .doc(eventInfo.id)
      .collection('Notices')
      .where('createdBy', '==', authUser)
      .orderBy('createdAt', 'desc')
      .onSnapshot((docResult) => {
        const resultData = [];
        docResult.forEach((doc) => {
          resultData.push(doc.data())
        })
        setNoticeData(resultData)
        setIsLoading(false)
      })

    return () => {
      unsubscribe();
    }

  }, [])


  const openNoticeAddModal = () => {
    setIsNoticeAddModal(true)
  }
  const closeNoticeAddModal = () => {
    setIsNoticeAddModal(false)
    setNoticeInfo(null)
  }
  const editNoticeItem = (noticeItem) => {
    setNoticeInfo(noticeItem)
    setIsNoticeAddModal(true)
  }
  const deleteNoticeItem = (itemId) => {
    if (window.confirm('해당 공지사항을 삭제하시겠습니까?')) {
      firebase.firestore().collection('Events')
        .doc(eventInfo.id)
        .collection('Notices')
        .doc(itemId)
        .delete()
        .then(() => {
          setShowToast(true);
        })
    }
  }

  return !isLoading ? (
      <>
        {renderToast()}
        <CssBaseline/>
        <Container maxWidth="lg" style={{overflow: 'auto'}}>
          <Typography variant="h5" className={classes.eventInfoTitle}>
            <AppMenuTitleEditable
              eventInfo={eventInfo}
              appMenuTitle={menuTitle}
              appMenuKey={menuKey}
            />
            <Button
              className={classes.appMenuAddBtn}
              variant="contained"
              color="primary"
              onClick={openNoticeAddModal}
            >
              공지사항 추가
            </Button>
          </Typography>
          <hr className={classes.mb30}/>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{width: '30%'}} className={classes.tableHeader}>내용</TableCell>
                  <TableCell className={classes.tableHeader} align="left">등록시각</TableCell>
                  <TableCell className={classes.tableHeader} align="center">앱푸시알림 여부</TableCell>
                  <TableCell className={classes.tableHeader} align="center">관리</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {noticeData && noticeData.length > 0 ?
                  noticeData.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {item.body.length < 20 ? item.body : item.body.substr(0, 20) + '...'}
                      </TableCell>
                      <TableCell align="left"> {printDateTime(item.updatedAt.toDate())}</TableCell>
                      <TableCell align="center">{item.isAppPush ? <CheckIcon/> : ''}</TableCell>
                      <TableCell align="center">
                        <button className={classes.noticeMangeBtn} onClick={() => editNoticeItem(item)}>편집</button>
                        <button className={classes.noticeMangeBtn} onClick={() => deleteNoticeItem(item.id)}>삭제</button>
                      </TableCell>
                    </TableRow>
                  ))
                  : (
                    <TableRow>
                      <TableCell colSpan={4} style={{textAlign: 'center'}}>
                        등록된 공지사항이 없습니다.
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Container>

        {
          isNoticeAddModal &&
          <NoticeManageModal
            noticeMenuId={menuKey}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            noticeInfo={noticeInfo}
            isModal={isNoticeAddModal}
            closeModal={closeNoticeAddModal}
            eventInfo={eventInfo}
          />
        }
      </>
    )
    : (
      <div className={classes.loadingWrap}>
        <CircularProgress/>
      </div>
    )
}
