import React, {useContext, useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FirebaseContext from "../../../Context/Firebase";
import {useHistory, useRouteMatch,} from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";
import EventNoteIcon from "@material-ui/icons/EventNote";
import ApartmentIcon from '@material-ui/icons/Apartment';
import ForumIcon from "@material-ui/icons/Forum";
import GroupIcon from "@material-ui/icons/Group";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import InfoIcon from '@material-ui/icons/Info';
import Typography from "@material-ui/core/Typography";
import {RandomKey} from "../../../Utill/Functions";
import {appMenuList} from '../../../Constant/SiteConfig';
import WebAssetIcon from '@material-ui/icons/WebAsset';

const useStyles = makeStyles(() => ({
  itemWrap: {
    padding: '10px',
    width: "180px",
    cursor: 'pointer',
    height: "180px",
  },
  item: {
    border: '1px solid #ccc',
    borderRadius: '10px',
    width: '100%',
    height: '100%',
    textAlign: 'center'
  },
  appIconWrap: {
    textAlign: 'center',
  },
  appIcon: {
    textAlign: 'center',
    fontSize: '30px',
    marginTop: '30px'
  },
  plusIcon: {
    marginTop: '15px',
    paddingTop: '10px',
    borderTop: '1px solid #ccc'
  },
  modalContentWrap: {
    minWidth: '300px',
    width: '90vw',
    maxWidth: '575px',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '20px'
  },
  modalTitle: {
    padding: '5px 10px',
    margin: '0 auto 10px auto',
    borderBottom: '1px solid #ccc'
  },
  appDescription: {
    fontSize: '12px',
    color: '#8e8e8e'
  },
  appTitle: {
    fontSize: '17px'
  }

}));

export default function AppMenuAddModal(props) {
  const firebase = useContext(FirebaseContext);
  const history = useHistory();
  const {url} = useRouteMatch();
  const classes = useStyles();
  const {
    isModal,
    closeModal,
    eventInfo,
    setNeedUpdate,
    needUpdate,
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isEventInfoMenuExists, setIsEventInfoMenuExists] = useState(null);
  const [isEventAttendMenuExists, setIsEventAttendMenuExists] = useState(null);

  useEffect(() => {
    setIsLoading(true)
    const isEventInfoMenu = Object.values(eventInfo.menus).some((item) => {
      return item.type === 'INFO';
    })
    const isAttendeeMenu = Object.values(eventInfo.menus).some((item) => {
      return item.type === 'ATTENDEE';
    })
    setIsEventInfoMenuExists(isEventInfoMenu)
    setIsEventAttendMenuExists(isAttendeeMenu)
    setIsLoading(false)
  }, [])

  const addAppMenu = (targetMenuValue) => {
    if (Object.keys(eventInfo.menus).length >= 39) {
      alert('생성가능한 앱메뉴 개수를 초과하였습니다.')
      return false;
    }

    if ((targetMenuValue === 2 && isEventAttendMenuExists) || (targetMenuValue === 6 && isEventInfoMenuExists)) {
      return false;
    }

    const RandomValue = RandomKey(4);
    const orderValue = Object.keys(eventInfo.menus).length + 1;
    firebase.firestore().collection('Events')
      .doc(eventInfo.id)
      .set({
        menus: {
          [RandomValue]: {
            icon: appMenuList[targetMenuValue].icon,
            order: orderValue,
            title: appMenuList[targetMenuValue].title,
            type: appMenuList[targetMenuValue].type,
            id: RandomValue,
          },
        }
      }, {merge: true})
      .then(() => {
        switch (appMenuList[targetMenuValue].type) {
          case 'AGENDA':
            history.push(`${url}/agendaManage?key=${RandomValue}`);
            break;
          case 'COMMUNITY':
            history.push(`${url}/postManage?key=${RandomValue}`);
            break;
          case 'WEB_VIEW':
            history.push(`${url}/webviewManage?key=${RandomValue}`);
            break;
          case 'WEB_LIST':
            history.push(`${url}/weblistManage?key=${RandomValue}`);
            break;
          default:
            setNeedUpdate(!needUpdate)
            closeModal()
        }
      })
  }


  const printAppIcon = (appMenuNum) => {
    switch (appMenuNum) {
      case 1:
        return <EventNoteIcon className={classes.appIcon}/>;
      case 2:
        return <GroupIcon className={classes.appIcon}/>;
        break;
      case 3:
        return <ForumIcon className={classes.appIcon}/>;
        break;
      case 4:
        return <FormatListBulletedIcon className={classes.appIcon}/>;
        break;
      case 5:
        return <InsertDriveFileIcon className={classes.appIcon}/>;
        break;
      case 6:
        return <InfoIcon className={classes.appIcon}/>;
        break;
      case 7:
        return <GroupIcon className={classes.appIcon}/>;
        break;
      case 8:
        return <ApartmentIcon className={classes.appIcon}/>;
        break;
      case 9:
        return <WebAssetIcon className={classes.appIcon}/>;
        break;
      default:
        return false;
    }
  }

  const AddAbleAppMenuList = [
    {num: 6, title: '행사개요', description: '행사 기본 정보 안내'},
    {num: 1, title: '프로그램', description: '행사 일정 안내'},
    {num: 2, title: '참가자', description: '행사 참가자 목록'},
    {num: 3, title: '커뮤니티', description: '피드 게시판'},
    {num: 4, title: '웹 링크', description: '웹사이트 링크 목록'},
    // {num: 5, title: '파일리스트', description: '파일 리스트'},
    // {num: 7, title: '인물 리스트', description: '행사 연사, 출연자 리스트'},
    // {num: 8, title: '기관 리스트', description: '부스, 스폰서, 셀러 리스트'},
    {num: 9, title: '외부 사이트', description: '외부 사이트 연결'},
  ]

  return !isLoading && (
    <div>
      <Dialog
        open={isModal}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className={classes.modalTitle}>
            <Typography variant="h6" className={classes.eventInfoTitle}>
              앱 메뉴 추가
            </Typography>
          </div>

          <div className={classes.modalContentWrap}>
            {
              AddAbleAppMenuList.map((appItem) => (

                appItem.num === 2 || appItem.num === 6
                  ? (
                    <div className={classes.itemWrap} onClick={() => addAppMenu(appItem.num)} key={appItem.num}>
                      <div className={classes.item}>
                        <div className={classes.appIconWrap}>
                          {printAppIcon(appItem.num)}
                        </div>
                        <p className={classes.appTitle}>
                          {appItem.title}
                        </p>
                        <p className={classes.appDescription}>
                          {appItem.description}
                        </p>

                        <div className={classes.plusIcon}>
                          {appItem.num === 2
                            ? (isEventAttendMenuExists ? <span>활성화됨</span> : <span>활성화하기</span>)
                            : (isEventInfoMenuExists ? <span>활성화됨</span> : <span>활성화하기</span>)
                          }

                        </div>
                      </div>
                    </div>
                  )
                  : (
                    <div className={classes.itemWrap} onClick={() => addAppMenu(appItem.num)} key={appItem.num}>
                      <div className={classes.item}>
                        <div className={classes.appIconWrap}>
                          {printAppIcon(appItem.num)}
                        </div>
                        <p className={classes.appTitle}>
                          {appItem.title}
                        </p>
                        <p className={classes.appDescription}>
                          {appItem.description}
                        </p>
                        <div className={classes.plusIcon}>
                          추가
                        </div>
                      </div>
                    </div>
                  )

              ))
            }
          </div>
        </DialogContent>
      </Dialog>

    </div>
  );
}
