import React, {useContext, useRef, useState, useEffect} from 'react'
import Box from "@material-ui/core/Box";
import {Field, Form, Formik} from "formik";
import Typography from "@material-ui/core/Typography";
import {TextField} from "formik-material-ui";
import Grid from "@material-ui/core/Grid";
import PostCodeModal from "../../../Component/Modals/PostCodeModal";
import DaumMap from "../../../Component/DaumMap/Index";
import DaumMapModify from "../../../Component/DaumMap/DaumMapModify";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import FirebaseContext from "../../../Context/Firebase";
import * as Yup from "yup";
import LoadingOverlay from "../../../Component/LoadingOverlay/Index";


const useStyles = makeStyles(() => ({
  button: {
    float: 'right',
    padding: '10px 50px'
  },
  mt30: {
    marginTop: '30px'
  },
}));

export default function EventBasicInfoPlace(props) {
  const formikRef = useRef();
  const classes = useStyles();
  const {eventInfo} = props;
  const firebase = useContext(FirebaseContext)
  const [isLoading, setIsLoading] = useState(false)
  const [isPostCodeModal, setIsPostCodeModal] = useState(false);
  const [addressText, setAddressText] = useState('');
  const [coordinate, setCoordinate] = useState({lat: '', lng: ''})

  useEffect(() => {
    setCoordinate(eventInfo.coordinate)
  }, [])

  const formValues = {
    place: eventInfo.place,
    address: eventInfo.address,
    detailAddress: eventInfo.detailAddress,
  }

  const validationSchema = Yup.object().shape({
    place: Yup.string()
      .required('행사장소를 입력해주세요'),
  });

  const openPostCodeModal = () => {
    setIsPostCodeModal(true)

  }
  const settingAddress = (address) => {
    setAddressText(address)
    if (formikRef.current) {
      formikRef.current.setFieldValue('address', address)
    }
  }
  const closePostCodeModal = () => {
    setIsPostCodeModal(false)
  }

  const changeAddress = (address) => {
    setAddressText(address)
    if (formikRef.current) {
      formikRef.current.setFieldValue('address', address)
    }
  }
  const onSubmit = (values) => {
    setIsLoading(true)
    const {
      place,
      address,
      detailAddress,
    } = values;

    firebase.firestore().collection('Events')
      .doc(eventInfo.id)
      .update({
        place: place,
        address: addressText ? addressText : address,
        detailAddress: detailAddress,
        coordinate: coordinate,
      }).then(() => {
      setIsLoading(false)
      window.scrollTo(0, 0)
    })
  }


  return (
    <>

      <Box mt={1} mb={1}>
        <Formik
          innerRef={formikRef}
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {
            ({values,}) => (
              <Form>
                <Typography variant="subtitle1" className={classes.mt30}>
                  행사 장소
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    <PostCodeModal
                      settingAddress={settingAddress}
                      isPostCodeModal={isPostCodeModal}
                      closePostCodeModal={closePostCodeModal}
                    />

                    {
                      values.address ?
                        (addressText
                            ? (
                              <DaumMap
                                changeAddress={changeAddress}
                                setCoordinate={setCoordinate}
                                addressText={addressText}
                              />
                            )
                            : (
                              <DaumMapModify
                                changeAddress={changeAddress}
                                setCoordinate={setCoordinate}
                                coordinate={eventInfo.coordinate}
                                addressText={eventInfo.addressText}
                              />
                            )
                        )
                        : ''
                    }

                    <Grid item xs={12} md={12} lg={12}>
                      <Field
                        disabled={false}
                        name="place"
                        component={TextField}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="장소명"
                      >
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Field
                        disabled
                        component={TextField}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        placeholder="주소입력(클릭해주세요)"
                        name="address"
                        onClick={() => openPostCodeModal()}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Field
                        disabled={false}
                        component={TextField}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        placeholder="상세주소(혹은 장소설명) 입력"
                        name="detailAddress"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Box mt={4}>
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12} className={classes.progressBtn}>
                      <Button
                        type="submit"
                        size="large"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        저장
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )
          }
        </Formik>
      </Box>
      {
        isLoading ?
          (<LoadingOverlay/>)
          : ''
      }
    </>
  )
}
