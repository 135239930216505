import React, {useContext,} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FirebaseContext from "../../../../Context/Firebase";
import {useFormik} from 'formik';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as Yup from 'yup';
import Button from "@material-ui/core/Button";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


const useStyles = makeStyles(() => ({
  itemWrap: {
    padding: '10px',
    width: "180px",
    cursor: 'pointer',
    height: "180px",
  },
  loadingWrap: {
    textAlign: 'center',
    width: '100%',
    padding: '10px 0'
  },
  item: {
    border: '1px solid #ccc',
    borderRadius: '10px',
    width: '100%',
    height: '100%',
    textAlign: 'center'
  },
  appIconWrap: {
    textAlign: 'center',
  },
  appIcon: {
    textAlign: 'center',
    fontSize: '30px',
    marginTop: '30px'
  },
  plusIcon: {
    marginTop: '15px',
    paddingTop: '10px',
    borderTop: '1px solid #ccc'
  },
  modalContentWrap: {
    minWidth: '300px',
    width: '90vw',
    maxWidth: '550px',

  },
  modalTitle: {
    padding: '5px 10px',
    margin: '0 auto 10px auto',
    borderBottom: '1px solid #ccc'
  },
  appDescription: {
    fontSize: '12px',
    color: '#8e8e8e'
  },
  appTitle: {
    fontSize: '17px'
  },
  errMsg: {
    float: 'left',
    color: '#f53838'
  },
  floatRight: {
    float: 'right'
  },
  clearBoth: {
    clear: 'both'
  }

}));

export default function NoticeManageModal(props) {
  const firebase = useContext(FirebaseContext);
  const classes = useStyles();
  const {
    isModal,
    closeModal,
    eventInfo,
    noticeInfo,
    noticeMenuId
  } = props;

  const sendNoticeFCM = (eventId, noticeId) => {
    const sendFCM = firebase.functions().httpsCallable('sendNoticeFCM');
    sendFCM({eventId: eventId, noticeId: noticeId, menuId: noticeMenuId}).then(function (result) {
      console.log('functionRESULT', result)
    })
      .catch(function (error) {
        console.log('error.code', error.code);
        console.log('error.message', error.message);
        console.log('error.codetailse', error.details);
      });
  }

  const onSubmit = (values, {setSubmitting}) => {

    if (noticeInfo) {
      firebase.firestore().collection('Events')
        .doc(eventInfo.id)
        .collection('Notices')
        .doc(noticeInfo.id)
        .update({
          body: values.noticeText,
          isAppPush: values.isAppPush,
          updatedAt: new Date()
        }).then(() => {
        if (values.isAppPush) {
          sendNoticeFCM(eventInfo.id, noticeInfo.id)
        }
        setSubmitting(false)
        closeModal();
      })

    } else {
      const newDocRef = firebase.firestore().collection('Events')
        .doc(eventInfo.id).collection('Notices').doc();
      newDocRef.set({
        id: newDocRef.id,
        body: values.noticeText,
        createdAt: new Date(),
        createdBy: eventInfo.createdBy,
        eventId: eventInfo.id,
        isAppPush: values.isAppPush,
        updatedAt: new Date(),
      })
        .then(() => {
          if (values.isAppPush) {
            sendNoticeFCM(eventInfo.id, newDocRef.id)
          }
          closeModal();
        })
    }

  }


  const {
    values,
    setFieldValue,
    errors,
    touched,
    setFieldTouched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting
  } = useFormik({
    initialValues: {noticeText: noticeInfo ? noticeInfo.body : '', isAppPush: false},
    validationSchema: Yup.object().shape({
      noticeText: Yup.string()
        .required('공지사항 내용을 입력해주세요.')
        .max(1000, '1000자 이내로 입력해주세요.')
    }),
    onSubmit,
  });


  const NoticeHandleChange = (value) => {
    setFieldTouched('noticeText', true)
    setFieldValue('noticeText', value.target.value)
  }

  return (
    <div>
      <Dialog
        open={isModal}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className={classes.modalTitle}>
            <Typography variant="h6" className={classes.eventInfoTitle}>
              공지사항
            </Typography>
          </div>
          <div className={classes.modalContentWrap}>
            <form onSubmit={handleSubmit}>
              <TextField
                onBlur={handleBlur}
                value={values.noticeText}
                name="noticeText"
                onChange={(value) => NoticeHandleChange(value)}
                variant="outlined"
                type="text"
                label="공지사항 내용을 입력해주세요."
                fullWidth
                multiline
                rows={6}
                margin="normal"
              />
              <div>
                <p className={classes.errMsg}>
                  {errors.noticeText && touched.noticeText && errors.noticeText}
                </p>
                <p className={classes.floatRight}>
                  {values.noticeText.length}/1000
                </p>
              </div>
              <div className={classes.clearBoth}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange}
                      name="isAppPush"
                      color="primary"
                    />
                  }
                  label="앱으로 푸시알림"
                />
              </div>
              <div style={{textAlign: 'right', padding: '20px 0'}}>
                <Button
                  type="button"
                  variant="contained"
                  color="default"
                  className={classes.submit}
                  size="large"
                  style={{marginRight: '10px'}}
                  onClick={() => closeModal()}
                >
                  취소
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  size="large"
                  disabled={isSubmitting}>
                  {
                    noticeInfo ? <span>수정</span> : <span>등록</span>
                  }
                </Button>
              </div>

            </form>
          </div>
        </DialogContent>
      </Dialog>

    </div>
  );
}
