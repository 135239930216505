import * as siteConfig from '../../../../Constant/SiteConfig';

import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import FirebaseContext from "../../../../Context/Firebase";
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {printDateDash,} from "../../../../Utill/Functions";
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import MaterialTable from "material-table";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import RemoveIcon from '@material-ui/icons/Remove';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import PostDetailModal from "./PostDetailModal";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
  eventInfoTitle: {
    paddingTop: '10px'
  },
  appMenuAddBtn: {
    float: 'right',
    marginLeft: '5px'
  },
  mb30: {
    margin: '15px 0 30px 0',
    height: '1px',
    backgroundColor: '#ccc',
    border: 'none'
  },
  tableHeader: {
    fontSize: '18px',
    fontWeight: 'bold'
  },
  noticeMangeBtn: {
    padding: '10px 20px',
    marginRight: '5px',
    background: '#fff',
    border: '1px solid #ccc',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  loadingWrap: {
    textAlign: 'center',
    width: '100%',
    padding: '10px 0'
  },
  appMenuTitleIcon: {
    position: 'relative',
    top: '3px',
    left: '5px',
    cursor: 'pointer'
  },
  editableInput: {
    width: '50%',
    padding: '5px 10px',
    display: 'inline'
  },
  avataImg: {
    borderRadius: '50%',
    width: '50px',
    height: '50px',
  }

}));
const theme = createMuiTheme();

export default function PostListView(props) {
  const {eventInfo, menuKey} = props;
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const [isLoading, setIsLoading] = useState(true);
  const [postData, setPostData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isPostDetailModal, setIsPostDetailModal] = useState(false);
  const [postDetailItemId, setPostDetailItemId] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('Events')
      .doc(eventInfo.id)
      .collection('Posts')
      .where('menuId', '==', menuKey)
      .orderBy('createdAt', 'asc')
      .onSnapshot((docResult) => {
        const resultDatas = [];
        docResult.forEach((doc) => {
          const data = {
            ...doc.data(),
            docId: doc.id,
            customCreatedAt: doc.data().createdAt.second,
          };
          resultDatas.push(data);
        })
        setPostData(resultDatas);
        setIsLoading(false)
      })
    return () => {
      unsubscribe();
    }

  }, [])


  const openPostDeatilModal = (item) => {
    setSelectedRow(item.id)
    setPostDetailItemId(item.docId)
    setIsPostDetailModal(true)

  }

  const closePostDetailModal = () => {
    setPostDetailItemId(null)
    setIsPostDetailModal(false)
  }


  return !isLoading ?
    (
      <>
        <CssBaseline/>
        <Container maxWidth="lg" style={{overflow: 'auto', padding: '0'}}>
          <MuiThemeProvider theme={theme}>
            <MaterialTable
              actions={[
                {
                  icon: () => <MoreHorizOutlinedIcon/>,
                  tooltip: '자세히보기',
                  onClick: (event, rowData) => openPostDeatilModal(rowData)
                }
              ]}
              columns={[
                {
                  title: '내용',
                  field: 'text',
                  headerStyle: {
                    fontSize: '18px',
                    fontWeight: 'bold'
                  },
                  render: (data) => (data.text.length < 20 ? data.text : data.text.substr(0, 20) + '...')
                },
                {
                  title: '게시자',
                  field: 'author.displayName',
                  headerStyle: {
                    fontSize: '18px',
                    fontWeight: 'bold'
                  },
                  width: 140,
                },
                {
                  title: '날짜',
                  width: 130,
                  field: 'customCreatedAt',
                  filtering: false,
                  headerStyle: {
                    textAlign: 'center',
                    fontSize: '18px',
                    fontWeight: 'bold'
                  },
                  defaultSort: 'desc',
                  render: (data) => printDateDash(data.createdAt.toDate())
                },
                {
                  title: '좋아요',
                  field: 'likeCount',
                  filtering: false,
                  width: 120,
                  align: 'center',
                  headerStyle: {
                    textAlign: 'center',
                    fontSize: '15px',
                  },
                },
                {
                  title: '댓글',
                  field: 'commentCount',
                  filtering: false,
                  width: 90,
                  align: 'center',
                  headerStyle: {
                    textAlign: 'center',
                    fontSize: '15px'
                  },
                  render: data => data.commentCount || 0,
                },
                {
                  title: '게시글 신고',
                  field: 'reportCount',
                  filtering: false,
                  width: 120,
                  align: 'center',
                  headerStyle: {
                    fontSize: '15px'
                  },
                  render: data => data.reportCount || 0,
                },
                {
                  title: '댓글 신고',
                  field: 'commentReportCount',
                  filtering: false,
                  width: 90,
                  align: 'center',
                  headerStyle: {
                    textAlign: 'center',
                    fontSize: '15px'
                  },
                  render: data => data.commentReportCount || 0,
                },
                {
                  title: '상태',
                  field: 'isHide',
                  width: 90,
                  filtering: false,
                  render: data => data.isHide ? '비공개' : '공개'
                }
              ]}
              data={postData}
              options={{
                rowStyle: rowData => ({
                  backgroundColor: (selectedRow === rowData.id) ? '#EEE' : '#FFF'
                }),
                actionsColumnIndex: -1,
                search: false,
                pageSize: 5,
                toolbar: false,
                pageSizeOptions: [5, 10, 20, 30, 50],
                exportButton: false,
                filtering: true,
              }}
              localization={
                {
                  ...siteConfig.tableSetLable,
                  grouping: {placeholder: '정렬하고자 하는 컬럼을 드래그해주세요.'},
                  toolbar: {exportName: ' CSV파일로 다운로드', nRowsSelected: '{0} 건이 선택되었습니다.'},
                  header: {actions: ''},
                }
              }
              icons={{
                Add: AddCircleIcon,
                Check: CheckBoxIcon,
                Clear: ClearIcon,
                Delete: DeleteIcon,
                DetailPanel: AddCircleIcon,
                Edit: EditIcon,
                Export: GetAppIcon,
                Filter: SearchIcon,
                FirstPage: FirstPageIcon,
                LastPage: LastPageIcon,
                NextPage: NavigateNextIcon,
                PreviousPage: NavigateBeforeIcon,
                ResetSearch: ClearIcon,
                Search: SearchIcon,
                SortArrow: ArrowDownwardIcon,
                ThirdStateCheck: RemoveIcon,
                ViewColumn: ViewColumnIcon,
              }}
            />
          </MuiThemeProvider>
        </Container>
        {
          isPostDetailModal &&
          <PostDetailModal
            eventInfo={eventInfo}
            postId={postDetailItemId}
            isModal={isPostDetailModal}
            closeModal={closePostDetailModal}
          />
        }
      </>
    )
    : (
      <div className={classes.loadingWrap}>
        <CircularProgress/>
      </div>
    )

}
