export const Root='/';
export const SignUp='/signUp';
export const Terms='/terms';

export const DashBoard ='/dashBoard'
export const ChangePassword ='/changePassword'
export const MemberInfoModify ='/MemberInfoModify'
export const CreateEvent ='/createEvent'
export const Withdrawal ='/withdrawal'

export const Test='/test';



