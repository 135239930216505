import React, { useContext, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import { Field, Formik, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import { Editor } from '@tinymce/tinymce-react';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import PostCodeModal from '../../Component/Modals/PostCodeModal';
import DaumMap from '../../Component/DaumMap/Index';
import FirebaseContext from '../../Context/Firebase';
import * as Yup from 'yup';
import LoadingOverlay from '../../Component/LoadingOverlay/Index';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import 'moment/locale/ko';
import AuthenticationContext from '../../Context/Authentication';
import { useHistory } from 'react-router-dom';
import * as Routes from '../../Constant/Routes';
import { RandomKey, getRandomKey, passcodeType, getKeywords } from '../../Utill/Functions';
import { tinyApiKey } from '../../Constant/SiteConfig';
import EventMainImageUploadModule from '../../Component/EventMainImageUploadModule/Index';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  mr15: {
    marginRight: '5px',
  },
  mt30: {
    marginTop: '30px',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  createEventTitle: {
    textAlign: 'center',
    background: '#4050b5',
    padding: '17px',
    color: '#fff',
    position: 'relative',
  },
  progressBtn: {
    textAlign: 'right',
  },
  cancelIcon: {
    cursor: 'pointer',
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
  eventStyleWrap: {
    padding: '15px',
    border: '1px solid #ccc',
    marginTop: '15px',
  },
  eventStyleDescription: {
    fontSize: '13px',
    color: '#716a61',
    textIndent: '14px',
    marginTop: '5px',
    display: 'block',
  },
  errorMsg: {
    color: '#f44638',
  },
  ErrorTextEditor: {
    border: '1px solid #f44638',
    marginBottom: '10px',
  },
}));

function getSteps() {
  return ['행사개요', '행사장소', '행사속성'];
}

export default function CreateEvent() {
  const classes = useStyles();
  const formikRef = useRef();
  const history = useHistory();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthenticationContext);
  const [isLoading, setIsLoading] = useState(false);
  const today = moment(new Date());
  const todayTime = moment('09:00', 'hh:mm');
  const tomorrow = moment(new Date()).add(1, 'days');
  const tomorrowTime = moment('18:00', 'hh:mm');
  const [isPostCodeModal, setIsPostCodeModal] = useState(false);
  const [addressText, setAddressText] = useState('');
  const [isEntrance, setIsEntrance] = useState(false);
  const [coordinate, setCoordinate] = useState({ lat: '', lng: '' });

  //파일업로드관련 로직 시작
  const [selectedFile, setSelectedFile] = useState(null);
  const fileUploadHandler = (docId) => {
    return new Promise((resolve) => {
      const RandomText = getRandomKey();
      const fileName = `${docId}_${RandomText}`;
      const storageRef = firebase.storage().ref(`images/${fileName}`);
      const task = storageRef.put(selectedFile);
      task.on(
        'state_changed',
        function progress(snapshot) {
          const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(percentage);
        },
        function error(err) {
          console.log(err);
        },
        function complete() {
          resolve(fileName);
        },
      );
    });
  };
  //파일업로드관련 로직 끝

  const openPostCodeModal = () => {
    setIsPostCodeModal(true);
  };
  const settingAddress = (address) => {
    setAddressText(address);
    if (formikRef.current) {
      formikRef.current.setFieldValue('address', address);
    }
  };
  const closePostCodeModal = () => {
    setIsPostCodeModal(false);
  };

  const changeEventStyle = (setFieldValue, value) => {
    setFieldValue('eventStyle', value);
    if (value === 'private') {
      setIsEntrance(true);
    } else {
      setIsEntrance(false);
      setFieldValue('passcode', '');
    }
  };
  const changeAddress = (address) => {
    setAddressText(address);
    if (formikRef.current) {
      formikRef.current.setFieldValue('address', address);
    }
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const handleNext = (setFieldTouched) => {
    if (activeStep === 0) {
      if (formikRef.current) {
        formikRef.current.validateForm().then((r) => {
          let flag = true;
          const errorArray = Object.keys(r);
          if (errorArray.includes('title')) {
            setFieldTouched('title', true);
            flag = false;
          }
          if (errorArray.includes('startDate')) {
            setFieldTouched('startDate', true);
            flag = false;
          }
          if (errorArray.includes('endDate')) {
            setFieldTouched('endDate', true);
            flag = false;
          }
          if (errorArray.includes('description')) {
            setFieldTouched('description', true);
            flag = false;
          }
          if (flag) {
            goNextFormView();
          }
        });
      }
    }

    if (activeStep === 1) {
      if (formikRef.current) {
        formikRef.current.validateForm().then((r) => {
          let flag = true;
          const errorArray = Object.keys(r);
          if (errorArray.includes('place')) {
            setFieldTouched('place', true);
            flag = false;
          }
          if (flag) {
            goNextFormView();
          }
        });
      }
    }
  };

  const goNextFormView = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const cancleCreateEvent = () => {
    if (window.confirm('행사생성을 취소하시겠습니까?')) {
      history.push(Routes.DashBoard);
    } else {
      return false;
    }
  };
  const initialValues = {
    title: '',
    description: '',
    place: '',
    eventStyle: 'public',
    passcode: '',
    startDate: today,
    endDate: tomorrow,
    startTime: todayTime,
    endTime: tomorrowTime,
    address: '',
    detailAddress: '',
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .min(1, '1자 이상 입력해주세요')
      .max(50, '50자 이하로 입력해주세요.')
      .required('행사명을 입력해주세요.'),
    description: Yup.string()
      .max(30000, '30000자 이하로 입력해주세요.')
      .required('행사소개를 입력해주세요.'),
    place: Yup.string().required('행사장소를 입력해주세요'),
    passcode: Yup.string()
      .min(1, '1자 이상 입력해주세요.')
      .max(10, '10자 이하로 입력해주세요.')
      .test('type', '입장코드는 숫자와 영문 조합으로 입력해주세요.', (value) =>
        passcodeType.test(value),
      ),
    startDate: Yup.mixed().test(
      'isBefore',
      '행사 시작날짜는 행사 종료날짜 이전으로 선택해 주세요.',
      function isBefore(value) {
        return value.isSameOrBefore(this.parent.endDate, 'day');
      },
    ),
    endDate: Yup.mixed().test(
      'isAfter',
      '행사 종료날짜는 행사 시작날짜 이후로 선택해 주세요.',
      function isBefore(value) {
        return value.isSameOrAfter(this.parent.startDate, 'day');
      },
    ),
  });
  const onSubmit = async (values) => {
    setIsLoading(true);
    const newDocRef = firebase.firestore().collection('Events').doc();
    let uploadedFileName = '';
    let imagePath = '';
    if (selectedFile !== null) {
      uploadedFileName = await fileUploadHandler(newDocRef.id);
      imagePath = `images/${uploadedFileName}`;
    }
    const {
      title,
      description,
      place,
      address,
      passcode,
      detailAddress,
      startDate,
      endDate,
      startTime,
      endTime,
    } = values;

    if (values.eventStyle === 'private') {
      if (values.passcode === '') {
        alert('입장코드를 입력해주세요.');
        setIsLoading(false);
        return false;
      }
    }
    const startAt = new Date(
      startDate.toDate().getFullYear(),
      startDate.toDate().getMonth(),
      startDate.toDate().getDate(),
      startTime.toDate().getHours(),
      startTime.toDate().getMinutes(),
    );
    const endAt = new Date(
      endDate.toDate().getFullYear(),
      endDate.toDate().getMonth(),
      endDate.toDate().getDate(),
      endTime.toDate().getHours(),
      endTime.toDate().getMinutes(),
    );
    const AppMenuNoticeRandomValue = RandomKey(4);
    const AppMenuEventInfoRandomValue = RandomKey(4);
    const AppMenuAttendeeRandomValue = RandomKey(4);
    newDocRef
      .set({
        id: newDocRef.id,
        title,
        menus: {
          [AppMenuNoticeRandomValue]: {
            icon: 'bell',
            order: 1,
            title: '공지사항',
            type: 'NOTICE',
            id: AppMenuNoticeRandomValue,
          },
          [AppMenuEventInfoRandomValue]: {
            icon: 'info',
            order: 2,
            title: '행사개요',
            type: 'INFO',
            id: AppMenuEventInfoRandomValue,
          },
          [AppMenuAttendeeRandomValue]: {
            icon: 'people',
            order: 3,
            title: '참가자',
            type: 'ATTENDEE',
            id: AppMenuAttendeeRandomValue,
          },
        },
        keywords: getKeywords(title),
        createdAt: new Date(),
        createdBy: authUser,
        description,
        startAt,
        endAt,
        place,
        address,
        detailAddress,
        coordinate,
        passcode,
        imagePath,
        state: 1,
      })
      .then(() => {
        setIsLoading(false);
        history.push(Routes.DashBoard);
      });
  };

  return (
    <>
      <CssBaseline />
      <Container maxWidth="md" style={{ minHeight: '80vh' }}>
        <Box mt={5} mb={1}>
          <Grid container>
            <Grid item xs={12} md={12} lg={12} className={classes.createEventTitle}>
              <Typography variant="h5">행사생성</Typography>
              <CancelIcon
                fontSize="large"
                className={classes.cancelIcon}
                onClick={() => cancleCreateEvent()}
              />
            </Grid>
          </Grid>
        </Box>
        <div className={classes.root}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <Box mt={4}>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values, setFieldTouched, setFieldValue, errors }) => (
                <Form className={classes.form}>
                  {activeStep === 0 && (
                    <div>
                      <Typography variant="h6">행사명</Typography>
                      <Field
                        disabled={false}
                        component={TextField}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="행사명을 입력해주세요."
                        name="title"
                      />

                      <Typography variant="h6" className={classes.mt30}>
                        행사 메인이미지
                      </Typography>
                      <EventMainImageUploadModule setSelectedFile={setSelectedFile} />

                      <Typography variant="h6" className={classes.mt30}>
                        행사 일정
                      </Typography>
                      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="ko">
                        <>
                          <Grid container spacing={1}>
                            <Grid item>
                              <DatePicker
                                disableToolbar
                                variant="dialog"
                                format="YYYY. MM. DD(dd)"
                                margin="normal"
                                id="date-picker-from"
                                label="행사시작 날짜"
                                name="startDate"
                                value={values.startDate}
                                onChange={(value) => setFieldValue('startDate', value)}
                                inputVariant="outlined"
                              />
                            </Grid>
                            <Grid item>
                              <TimePicker
                                label="행사 시작시각"
                                inputVariant="outlined"
                                mask="__:__ _M"
                                margin="normal"
                                name="startTime"
                                value={values.startTime}
                                onChange={(value) => setFieldValue('startTime', value)}
                              />
                            </Grid>
                          </Grid>

                          {errors.startDate && (
                            <p className={classes.errorMsg}>{errors.startDate}</p>
                          )}

                          <Grid container spacing={1}>
                            <Grid item>
                              <DatePicker
                                disableToolbar
                                variant="dialog"
                                format="YYYY. MM. DD(dd)"
                                margin="normal"
                                inputVariant="outlined"
                                id="date-picker-to"
                                label="행사종료 날짜"
                                name="endDate"
                                value={values.endDate}
                                onChange={(value) => setFieldValue('endDate', value)}
                              />
                            </Grid>

                            <Grid item>
                              <TimePicker
                                label="행사 종료시각"
                                inputVariant="outlined"
                                margin="normal"
                                mask="__:__ _M"
                                name="endTime"
                                value={values.endTime}
                                onChange={(value) => setFieldValue('endTime', value)}
                              />
                            </Grid>
                          </Grid>
                          {errors.endDate && <p className={classes.errorMsg}>{errors.endDate} </p>}
                        </>
                      </MuiPickersUtilsProvider>

                      <Typography variant="h6" className={classes.mt30}>
                        행사 소개
                      </Typography>
                      <br />
                      <div className={errors.description ? classes.ErrorTextEditor : ''}>
                        <Editor
                          apiKey={tinyApiKey}
                          branding={false}
                          init={{
                            height: 400,
                            menubar: false,
                            plugins: [
                              'advlist autolink lists link image',
                              'charmap print preview anchor help',
                              'searchreplace visualblocks code',
                              'insertdatetime media table paste wordcount',
                            ],
                            toolbar:
                              'undo redo | formatselect | bold forecolor backcolor italic | \
                              alignleft aligncenter alignright | \
                              bullist numlist outdent indent',
                            block_formats: '본문=p; 제목 1=h1; 제목 2=h2; 제목 3=h3; 제목 4=h4; 제목 5=h5; 제목 6=h6;'
                          }}
                          value={values.description}
                          onEditorChange={(value) => setFieldValue('description', value)}
                        />
                      </div>
                      {errors.description && (
                        <p className={classes.errorMsg}>{errors.description} </p>
                      )}
                    </div>
                  )}
                  {activeStep === 1 && (
                    <div>
                      <Typography variant="h6" className={classes.mt30}>
                        행사 장소
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={12} lg={12}>
                          <PostCodeModal
                            settingAddress={settingAddress}
                            isPostCodeModal={isPostCodeModal}
                            closePostCodeModal={closePostCodeModal}
                          />
                          {addressText && (
                            <DaumMap
                              changeAddress={changeAddress}
                              setCoordinate={setCoordinate}
                              addressText={addressText}
                            />
                          )}
                          <Grid item xs={12} md={12} lg={12}>
                            <Field
                              disabled={false}
                              name="place"
                              component={TextField}
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              label="장소명"
                            ></Field>
                          </Grid>
                          <Grid item xs={12} md={12} lg={12}>
                            <Field
                              disabled
                              component={TextField}
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              placeholder="주소입력(클릭해주세요)"
                              name="address"
                              onClick={() => openPostCodeModal()}
                            />
                          </Grid>
                          <Grid item xs={12} md={12} lg={12}>
                            <Field
                              disabled={false}
                              component={TextField}
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              placeholder="상세주소(혹은 장소설명) 입력"
                              name="detailAddress"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  )}

                  {activeStep === 2 && (
                    <div>
                      <div className={classes.eventStyleWrap}>
                        <Typography variant="body2">
                          <label>
                            <input
                              type="radio"
                              name="eventStyle"
                              value="public"
                              checked={values.eventStyle === 'public'}
                              onChange={() => changeEventStyle(setFieldValue, 'public')}
                            />
                            공개행사
                          </label>
                          <span className={classes.eventStyleDescription}>
                            행사 리스트에 게시가 되며, 누구나 참여할 수 있습니다.
                          </span>
                        </Typography>
                      </div>

                      <div className={classes.eventStyleWrap}>
                        <Typography variant="body2">
                          <label>
                            <input
                              type="radio"
                              name="eventStyle"
                              value="private"
                              checked={values.eventStyle === 'private'}
                              onChange={() => changeEventStyle(setFieldValue, 'private')}
                            />
                            입장코드 행사
                          </label>
                          <span className={classes.eventStyleDescription}>
                            행사 리스트에 게시가 되며, 입장코드를 입력해야 참여할 수 있습니다.
                          </span>
                        </Typography>
                        {isEntrance && (
                          <Field
                            disabled={false}
                            component={TextField}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            placeholder="행사참석시 입력해야할 행사코드를 입력해주세요."
                            name="passcode"
                          />
                        )}
                      </div>
                    </div>
                  )}

                  <Box mt={4}>
                    <Grid container>
                      <Grid item xs={12} md={12} lg={12} className={classes.progressBtn}>
                        {activeStep !== 0 && (
                          <Button
                            startIcon={<ArrowLeftIcon />}
                            onClick={handleBack}
                            className={classes.button}
                            variant="outlined"
                          >
                            이전단계
                          </Button>
                        )}
                        {activeStep !== 2 && (
                          <Button
                            endIcon={<ArrowRightIcon />}
                            variant="contained"
                            color="primary"
                            onClick={() => handleNext(setFieldTouched)}
                            className={classes.button}
                          >
                            다음단계
                          </Button>
                        )}

                        {activeStep === 2 && (
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                          >
                            행사 생성하기
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </div>
      </Container>
      {isLoading ? <LoadingOverlay /> : ''}
    </>
  );
}
