import React, {useEffect, useContext, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import FirebaseContext from "../../Context/Firebase";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";


export default function Terms() {

  const firebase = useContext(FirebaseContext);
  const [isLoading, setIsLoading] = useState(true);
  const [terms, setTerms] = useState();

  useEffect(() => {
    firebase.firestore().collection('Service').doc('terms')
      .get()
      .then((docResult) => {
        const termText = docResult.data().service.kr;
        const filterText = termText.replace(/\\n/g, '<br/>');
        setTerms(filterText);
        setIsLoading(false)
      })
  }, [])

  return !isLoading && (
    <>
      <CssBaseline/>

      <Container maxWidth="lg" style={{minHeight: '80vh'}}>
        <Box mt={5}>
          <Typography variant="h4">
            서비스 이용약관
          </Typography>

          <div dangerouslySetInnerHTML={ {__html: terms} }>
          </div>

        </Box>
      </Container>
    </>
  )

}
