import React from 'react';
import styles from "../../Utill/MaterialUiKitProFiles/footerStyle.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {makeStyles} from "@material-ui/core/styles";
import Footer from './Footer';

const useStyles = makeStyles(styles);

export default function FooterIndex() {
  const classes = useStyles();
  return (
    <Footer
      theme="white"
      content={
        <div style={{padding: '10px 0'}}>
          <div className={classes.left}>
            <span className={classes.footerBrand} >
              Nolla
            </span>
          </div>
          <div className={classes.rightLinks}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <p className={classes.block} >
                  푸터정보01
                </p>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <p className={classes.block} >
                  푸터정보01
                </p>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <p className={classes.block} >
                  푸터정보01
                </p>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <p className={classes.block} >
                  푸터정보01
                </p>
              </ListItem>
            </List>
          </div>

        </div>
      }
    />
  )
}
