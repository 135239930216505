import React, {useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import DragableAppMenu from './DragableAppMenu';
import Button from "@material-ui/core/Button";

import AppMenuAddModal from "./AppMenuAddModal";


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  eventInfoTitle: {
    paddingTop: '10px'
  },
  appMenuAddBtn: {
    float: 'right'
  },
  mb30: {
    margin: '15px 0 30px 0',
    height: '1px',
    backgroundColor: '#ccc',
    border: 'none'
  },
  appMenuWrap: {
    maxWidth: '600px',
    minWidth: '500px',
    width: '100%',
    margin: '0 auto',
    height: '100%',
  },

}));

export default function AppMenuManage(props) {
  const {eventInfo,} = props;
  const classes = useStyles();
  const [isAppMenuAddModal, setIsAppMenuAddModal] = useState(false);
  const [needUpdate, setNeedUpdate] = useState(false);


  const openAppMenuAddModal = () => {
    setIsAppMenuAddModal(true)

  }
  const closeAppMenuAddModal = () => {
    setIsAppMenuAddModal(false)
  }

  return (
    <>
      <CssBaseline/>
      <Container maxWidth="lg" style={{overflow: 'auto'}}>
        <Typography variant="h5" className={classes.eventInfoTitle}>
          앱 메뉴관리
          <Button
            className={classes.appMenuAddBtn}
            variant="contained"
            color="primary"
            onClick={() => openAppMenuAddModal()}
          >
            메뉴추가하기
          </Button>
        </Typography>

        <hr className={classes.mb30}/>
        <div className={classes.appMenuWrap}>
          <DragableAppMenu
            eventInfo={eventInfo}
            needUpdate={needUpdate}
            setNeedUpdate={setNeedUpdate}
          />
        </div>
      </Container>

      {
        isAppMenuAddModal &&
        <AppMenuAddModal
          isModal={isAppMenuAddModal}
          closeModal={closeAppMenuAddModal}
          eventInfo={eventInfo}
          setNeedUpdate={setNeedUpdate}
          needUpdate={needUpdate}
        />
      }


    </>
  )

}
