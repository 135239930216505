/*global kakao*/
import React, {useEffect,} from 'react';


const DaumMapModify = (props) => {
  const {setCoordinate, coordinate, changeAddress} = props;


  useEffect(() => {

    var mapContainer = document.getElementById('map'), // 지도를 표시할 div
      mapOption = {
        center: new kakao.maps.LatLng(coordinate.lat, coordinate.lng), // 지도의 중심좌표
        level: 3 // 지도의 확대 레벨
      };

    var map = new kakao.maps.Map(mapContainer, mapOption); // 지도를 생성합니다
    let geocoder = new kakao.maps.services.Geocoder();
// 지도를 클릭한 위치에 표출할 마커입니다
    var marker = new kakao.maps.Marker({
      // 지도 중심좌표에 마커를 생성합니다
      position: map.getCenter()
    });
// 지도에 마커를 표시합니다
    marker.setMap(map);
    marker.setDraggable(true);
    kakao.maps.event.addListener(marker, 'dragend', function () {
      let coord = new kakao.maps.LatLng(marker.getPosition().getLat(), marker.getPosition().getLng());

      searchDetailAddrFromCoords(coord, function (result, status) {
        if (status === kakao.maps.services.Status.OK) {
          if (!!result[0].road_address) {
            changeAddress(result[0].road_address.address_name)
          }
          marker.setPosition(coord);
          marker.setMap(map);
          setCoordinate({
            lat: marker.getPosition().getLat(),
            lng: marker.getPosition().getLng()
          })
        }
      });
      map.setCenter(coord);
    });

    function searchDetailAddrFromCoords(coords, callback) {
      geocoder.coord2Address(coords.getLng(), coords.getLat(), callback);
    }


  }, [])


  return (
    <div className='popup'>
      <div id='map' style={{width: '100%', height: '300px'}}/>
      <div style={{padding: '5px 0', textAlign: 'right', fontSize: '12px'}}>
        *검색한 주소결과의 마커위치가 정확하지 않을경우 마우스로 마커를 이동시켜주세요.
      </div>


    </div>
  )
}

export default DaumMapModify;
