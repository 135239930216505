/*global kakao*/
import React, {useEffect,} from 'react';


const DaumMap = (props) => {
  const {addressText, setCoordinate, changeAddress} = props;

  useEffect(() => {

    let mapContainer = document.getElementById('map'),
      mapOption = {
        center: new kakao.maps.LatLng(33.450701, 126.570667),
        level: 3
      };
    let map = new kakao.maps.Map(mapContainer, mapOption);
    let geocoder = new kakao.maps.services.Geocoder();

    geocoder.addressSearch(addressText, function (result, status) {
      if (status === kakao.maps.services.Status.OK) {
        let coords = new kakao.maps.LatLng(result[0].y, result[0].x);
        setCoordinate({
          lat: result[0].y,
          lng: result[0].x
        })

        let marker = new kakao.maps.Marker({
          map: map,
          position: coords
        });

        marker.setDraggable(true);

        kakao.maps.event.addListener(marker, 'dragend', function () {
          let coord = new kakao.maps.LatLng(marker.getPosition().getLat(), marker.getPosition().getLng());

          searchDetailAddrFromCoords(coord, function (result, status) {
            if (status === kakao.maps.services.Status.OK) {
              if (!!result[0].road_address) {
                changeAddress(result[0].road_address.address_name)
              }
              marker.setPosition(coord);
              marker.setMap(map);
              setCoordinate({
                lat: marker.getPosition().getLat(),
                lng: marker.getPosition().getLng()
              })
            }
          });
          map.setCenter(coord);
        });
        map.setCenter(coords);
      }
    });

    function searchDetailAddrFromCoords(coords, callback) {
      geocoder.coord2Address(coords.getLng(), coords.getLat(), callback);
    }


  }, [addressText])


  return (
    <div className='popup'>
      <div id='map' style={{width: '100%', height: '300px'}}/>
      <div style={{padding: '5px 0', textAlign: 'right', fontSize: '12px'}}>
        *검색한 주소결과의 마커위치가 정확하지 않을경우 마우스로 마커를 이동시켜주세요.
      </div>


    </div>
  )
}

export default DaumMap;
