// 계정찾기 중 이메일 특정 부분 별표표시
export const replaceEmailInfo = (value) => {
  const selectEmailLoaction = value.indexOf('@');
  const targetChar = value.substring(selectEmailLoaction - 3, selectEmailLoaction);
  return value.replace(targetChar, '***');
};

// 랜덤 키 생성 함수
export const RandomKey = (length) => {
  const chars = '123456789ABCDEFGHIJKLMNPQRSTUVWXTZ';
  let randomString = '';
  for (let i = 0; i < length; i++) {
    const rnum = Math.floor(Math.random() * chars.length);
    randomString += chars.substring(rnum, rnum + 1);
  }
  return randomString;
};


export const getRandomKey = (length = 6) => {
  const now = new Date();
  const createdAt = `${now.getFullYear()}-${now.getMonth() +
  1}-${now.getDate()}-${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}`;
  const hash = Math.random()
    .toString(20)
    .substr(2, length);
  return `${createdAt}-${hash}`;
};

export const RandomNum = (length) => {
  const chars = '123456789';
  let randomString = '';
  for (let i = 0; i < length; i++) {
    const rnum = Math.floor(Math.random() * chars.length);
    randomString += chars.substring(rnum, rnum + 1);
  }

  return randomString;
};

// 날짜 출력 함수
export const printDate = (value) => {
  var week = ['일', '월', '화', '수', '목', '금', '토'];
  const date = value.getDate();
  const month = value.getMonth();
  const year = value.getFullYear();
  const day = value.getDay();
  const dateString = `${year}년 ${month + 1}월 ${date}일 (${week[day]})`;
  return dateString;
};
export const printDateTime = (value) => {
  var week = ['일', '월', '화', '수', '목', '금', '토'];
  const date = value.getDate();
  const month = value.getMonth();
  const year = value.getFullYear();
  const day = value.getDay();
  const hour = ('0' + value.getHours()).substr(-2);
  const minutes = ('0' + value.getMinutes()).substr(-2);
  const dateString = `${year}년 ${month + 1}월 ${date}일 (${week[day]}) ${hour} : ${minutes}`;
  return dateString;
};

export const printTime = (value) => {
  const hour = ('0' + value.getHours()).substr(-2);
  const minutes = ('0' + value.getMinutes()).substr(-2);
  const time = `${hour}:${minutes}`;
  return time;
}

//ex)2020-08-11
export const printDateDash = (value) => {
  const date = ("0" + value.getDate()).slice(-2);
  const month = ("0" + (value.getMonth() + 1)).slice(-2);
  const year = value.getFullYear();
  const dateString = `${year}-${month}-${date}`;
  return dateString;
};

export const autoHypenPhone = (value) => {
  const str = value.replace(/[^0-9]/g, '');
  let tmp = '';
  if (str.length < 4) {
    return str;
  }
  if (str.length < 7) {
    tmp += str.substr(0, 3);
    tmp += '-';
    tmp += str.substr(3);
    return tmp;
  }
  if (str.length < 11) {
    tmp += str.substr(0, 3);
    tmp += '-';
    tmp += str.substr(3, 3);
    tmp += '-';
    tmp += str.substr(6);
    return tmp;
  }
  tmp += str.substr(0, 3);
  tmp += '-';
  tmp += str.substr(3, 4);
  tmp += '-';
  tmp += str.substr(7);
  return tmp;
};


export const getCookie = (name) => {
  let i;
  let x;
  let y;
  const ARRcookies = document.cookie.split(';');
  for (i = 0; i < ARRcookies.length; i++) {
    x = ARRcookies[i].substr(0, ARRcookies[i].indexOf('='));
    y = ARRcookies[i].substr(ARRcookies[i].indexOf('=') + 1);
    x = x.replace(/^\s+|\s+$/g, '');
    if (x === name) {
      return unescape(y);
    }
  }
};

export const setCookie = (cName, value, expireDays) => {
  const expireDate = new Date();
  expireDate.setDate(expireDate.getDate() + expireDays);
  const cValue = escape(value) + ((expireDays === null) ? '' : `; expires=${expireDate.toUTCString()};`);
  document.cookie = `${cName}=${cValue}`;
};


export const getKeywords = (sentence) => {
  const regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
  const keywords = new Set();
  // 특수문자 제거 및 영문 소문자 변경
  // **note: client 검색 시 검색어 소문자로 변경 필요
  const trimmed = sentence.replace(regExp, '').toLowerCase();
  // 띄어쓰기로 단어 구분
  const words = trimmed.split(' ');
  // 단어 조합 생성
  words.forEach(word => {
    // 음절로 구분
    word.split('').forEach((letter, index) => {
      for (let i = index + 1; i <= word.length; i++) {
        keywords.add(word.slice(index, i));
      }
    })
  })
  // 배열로 변환
  return Array.from(keywords.values());
}

export const passcodeType = /^[A-Za-z0-9+]*$/;

export const preventEnterKeySubmit = (keyEvent) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}

export const removeFileTypeText = (value) => {
  const pointLocation = value.lastIndexOf('.');
  if (pointLocation === -1) {
    return value;
  }
  return value.substr(0, pointLocation);
}


export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: {...item},
    };
  }, initialValue);
};


export const getExtensionOfFilename = (filename) => {
  let fileLength = filename.length;
  let lastDot = filename.lastIndexOf('.');
  let fileExt = filename.substring(lastDot + 1, fileLength).toUpperCase();
  return fileExt;
}


// export const checkProgramUploadFileType = (value) => {
//   const allowedWordFileTypes = ['doc', 'dot', 'wbk', 'docx', 'docm', 'dotx', 'dotm', 'docb'];
//   const allowedExcelFileTypes = ['xls', 'xlt', 'xlm', 'xlsx', 'xlsm', 'xltx', 'xltm', 'xlsb', 'xla', 'xlam', 'xll', 'xlw'];
//   const alloewdPptFileTypes = ['ppt', 'pot', 'pps', 'pptx', 'pptm', 'potx', 'potm', 'ppam', 'ppsx', 'sldx', 'sldm'];
//   const allowedPdfFileTypes = ['pdf'];
//   const allowedImages = ['jpeg', 'jpg', 'png'];
// }
//
