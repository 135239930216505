import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import HelpIcon from '@material-ui/icons/Help';
import Button from '@material-ui/core/Button';
import FirebaseContext from '../../Context/Firebase';
import {useHistory} from 'react-router-dom';
import * as Routes from '../../Constant/Routes';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  button: {
    boxShadow: 'none',
  },
}));

export default function Header() {
  const classes = useStyles();
  const history = useHistory();
  const firebase = useContext(FirebaseContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [guideAnchorEl, setguideAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isGuideMenuOpen = Boolean(guideAnchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const signOut = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    firebase.auth().signOut();
    history.push(Routes.Root);
  };
  const goChangePassword = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    history.push(Routes.ChangePassword);
  }
  const MemberInfoModify = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    history.push(Routes.MemberInfoModify);
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleGuideMenuOpen = (event) => {
    setguideAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleGuideMenuClose = () => {
    setguideAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const guideMenuId = 'primary-search-account-guidemenu';

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={menuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => MemberInfoModify()}>
        내 정보수정
      </MenuItem>
      <MenuItem onClick={() => goChangePassword()}>
        비밀번호 변경
      </MenuItem>
      <MenuItem onClick={() => signOut()}>
        로그아웃
      </MenuItem>
    </Menu>
  );


  const renderGuideMenu = (
    <Menu
      anchorEl={guideAnchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={guideMenuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={isGuideMenuOpen}
      onClose={handleGuideMenuClose}
    >
      <MenuItem>
        사용법
      </MenuItem>
      <MenuItem>
        강의영상
      </MenuItem>

    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        사용법
      </MenuItem>
      <MenuItem>
        강의영상
      </MenuItem>
      <MenuItem onClick={() => MemberInfoModify()}>
        내 정보수정
      </MenuItem>
      <MenuItem onClick={() => goChangePassword()}>
        비밀번호 변경
      </MenuItem>
      <MenuItem onClick={() => signOut()}>
        로그아웃
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" noWrap>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push(Routes.DashBoard)}
            >
              Nolla
            </Button>
          </Typography>
          <div className={classes.grow}/>
          <div className={classes.sectionDesktop}>
            <Button
              variant="contained"
              color="primary"
              aria-controls={guideMenuId}
              onClick={handleGuideMenuOpen}
              className={classes.button}
              startIcon={<HelpIcon/>}
            >
              도움말
            </Button>


            <Button
              variant="contained"
              color="primary"
              aria-controls={menuId}
              onClick={handleProfileMenuOpen}
              className={classes.button}
              startIcon={<AccountCircle/>}
            >
              내 계정
            </Button>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon/>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderGuideMenu}
      {renderMenu}
    </div>
  );
}
