import React, {useContext, useState, useEffect,} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FirebaseContext from "../../../../Context/Firebase";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as Yup from 'yup';
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {printDateTime,} from "../../../../Utill/Functions";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {TextField,} from 'formik-material-ui';
import {Field, Formik, Form,} from 'formik';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PostDetailComments from "./PostDetailComments";
import CommentIcon from '@material-ui/icons/Comment';

const useStyles = makeStyles(() => ({

  loadingWrap: {
    textAlign: 'center',
    width: '100%',
    padding: '10px 0'
  },

  modalContentWrap: {
    minWidth: '300px',
    width: '90vw',
    maxWidth: '550px',

  },
  modalTitle: {
    padding: '5px 10px',
    margin: '0 auto 10px auto',
  },
  bold: {
    fontWeight: 'bold'
  },
  appDescription: {
    fontSize: '12px',
    color: '#8e8e8e'
  },
  appTitle: {
    fontSize: '17px'
  },
  errMsg: {
    color: '#f53838'
  },
  floatRight: {
    float: 'right'
  },
  clearBoth: {
    clear: 'both'
  },
  avataImg: {
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    verticalAlign: 'middle'
  },
  eventInfoTitle: {
    display: 'inline-block',
    position: 'relative',
    top: '7px',
    paddingLeft: '10px'
  },
  postText: {
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    marginBottom: '10px'
  },
  verticalBar: {
    fontWeight: 'bold',
    margin: '0 10px',
    color: '#ccc'
  },
  postInfoArea: {
    marginBottom: '5px',
    textAlign: 'left',
  },
  postControlArea: {
    textAlign: 'right',
    marginBottom: '10px'
  },
  postHideArea: {
    marginBottom: '10px'
  },
  commentArea: {
    marginTop: '20px',
    padding: '10px',
    marginBottom: '10px'
  },
  commentDisplayName: {
    fontWeight: 'bold',
    fontSize: '15px',
    marginRight: '10px'
  },
  commentItemWrap: {
    borderBottom: '1px solid #e6e6e6',
    padding: '5px 0'
  },
  hiddenReasonAre: {
    textAlign: 'center',
    padding: '10px 0',
    borderRadius: '5px',
    border: '1px solid #f53838',
    marginBottom: '5px'
  },
  textLineThrough: {
    textDecoration: 'line-through'
  },


}));

export default function PostDetailModal(props) {
  const {
    //공통 props
    isModal, closeModal, eventInfo,
    //게시글 항목
    postId,
    //신고내역 항목
    postInfo, isReportsModal, reportInfo
  } = props;

  const firebase = useContext(FirebaseContext);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);

  const [isPostHideForm, setIsPostHideForm] = useState(false);
  const [postDetailItem, setPostDetailItem] = useState(null);


  useEffect(() => {
    const postIdValue = isReportsModal ?
      postInfo.targetCollection === "Posts" ? postInfo.targetId : postInfo.postId : postId;
    const unsubscribe = firebase.firestore().collection('Events')
      .doc(eventInfo.id)
      .collection('Posts')
      .doc(postIdValue)
      .onSnapshot((doc) => {
        const data = {
          ...doc.data(),
          docId: doc.id,
          customCreatedAt: doc.data().createdAt.toDate().getTime(),
        };
        setPostDetailItem(data);
        setIsLoading(false)
      })
    return () => {
      unsubscribe();
    }

  }, [])


  const validationSchema = Yup.object().shape({
    postHideReason: Yup.string()
      .max(100, '100자 이하로 입력해주세요')
      .required('게시물 감추기 사유를 입력해주세요.')
  })

  const PostHideOnsubmit = (value) => {

    if (isReportsModal) {
      const postIdValue = reportInfo.targetCollection === "Posts" ? reportInfo.targetId : reportInfo.postId;
      firebase.firestore().collection('Events')
        .doc(eventInfo.id)
        .collection('Posts')
        .doc(postIdValue)
        .set({
          isHide: true,
          postHideReason: value.postHideReason
        }, {merge: true})
        .then(() => {
          setIsPostHideForm(false)
        })
    } else {
      firebase.firestore().collection('Events')
        .doc(eventInfo.id)
        .collection('Posts')
        .doc(postId)
        .set({
          isHide: true,
          postHideReason: value.postHideReason
        }, {merge: true})
        .then(() => {
          setIsPostHideForm(false)
        })
    }

  }

  const cancelPostHide = () => {
    if (window.confirm('해당 게시물을 다시 노출하시겠습니까?')) {
      if (isReportsModal) {
        const postIdValue = reportInfo.targetCollection === "Posts" ? reportInfo.targetId : reportInfo.postId;
        firebase.firestore().collection('Events')
          .doc(eventInfo.id)
          .collection('Posts')
          .doc(postIdValue)
          .set({
            isHide: false,
            postHideReason: ''
          }, {merge: true})
          .then(() => {
            setIsPostHideForm(false)
          })
      } else {
        firebase.firestore().collection('Events')
          .doc(eventInfo.id)
          .collection('Posts')
          .doc(postId)
          .set({
            isHide: false,
            postHideReason: ''
          }, {merge: true})
          .then(() => {
            setIsPostHideForm(false)
          })
      }

    }
  }

  return !isLoading ? (
      <>
        <Dialog
          open={isModal}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            {console.log('postDetailItem', postDetailItem)}
            <div className={classes.modalTitle}>
              {postDetailItem.author.avatar
                ? <img alt="" className={classes.avataImg} src={postDetailItem.author.avatar} />
                : <AccountCircleIcon className={classes.avataImg}/>
              }
              <Typography variant="h6" className={classes.eventInfoTitle}>
                {postDetailItem.author.displayName}( {postDetailItem.author.userId} )
              </Typography>
            </div>
            <div className={classes.modalContentWrap}>

              <div className={classes.postInfoArea}>
                생성일 : {printDateTime(postDetailItem.createdAt.toDate())}
                {
                  postDetailItem.updatedAt &&
                  <>
                    <span className={classes.verticalBar}>|</span>최근수정:{printDateTime(postDetailItem.updatedAt.toDate())}
                  </>
                }
              </div>
              <div className={classes.postInfoArea}>
                좋아요 : {postDetailItem.likeCount || 0} 건
                <span className={classes.verticalBar}>|</span>
                댓글 : {postDetailItem.commentCount || 0} 건
                <span className={classes.verticalBar}>|</span>
                게시물 신고 : {postDetailItem.reportCount || 0} 건
                <span className={classes.verticalBar}>|</span>
                댓글 신고 : {postDetailItem.commentReportCount || 0} 건
              </div>
              <br/>
              <div className={classes.postText}>
                {postDetailItem.images && postDetailItem.images.length > 0 &&
                postDetailItem.images.map((item) => (
                    <img alt="" key={item.id} src={item.url} style={{width: '100%'}}/>
                  )
                )
                }
                <br/>
                <p className={postDetailItem.isHide ? classes.textLineThrough : ''}>
                  {postDetailItem.text}
                </p>

              </div>

              {
                postDetailItem.isHide ? (
                    <div className={classes.postHideArea}>
                      <div className={classes.hiddenReasonAre}>
                        <span className={classes.errMsg}> 비공개 사유 : {postDetailItem.postHideReason}</span>
                      </div>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<VisibilityIcon/>}
                        onClick={() => cancelPostHide()}
                        fullWidth
                      >
                        게시물 노출하기
                      </Button>
                    </div>
                  )
                  : (
                    isPostHideForm === true
                      ? ''
                      : (
                        <div className={classes.postControlArea}>
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            startIcon={<VisibilityOffIcon/>}
                            onClick={() => setIsPostHideForm(true)}
                          >
                            게시물 감추기
                          </Button>
                        </div>
                      )
                  )
              }


              {isPostHideForm &&
              <div className={classes.postControlArea}>
                <Formik
                  initialValues={{postHideReason: ''}}
                  validationSchema={validationSchema}
                  onSubmit={PostHideOnsubmit}
                >
                  {
                    () => (
                      <Form>
                        <Field
                          disabled={false}
                          component={TextField}
                          variant="outlined"
                          margin="normal"
                          lable="게시물 감추기 사유"
                          name="postHideReason"
                          placeholder="감추기 사유를 입력해주세요."
                          fullWidth
                        />
                        <Button
                          type="button"
                          variant="contained"
                          color="default"
                          style={{marginRight: '5px'}}
                          className={classes.button}
                          onClick={() => setIsPostHideForm(false)}
                        >
                          취소
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.button}
                        >
                          감추기
                        </Button>
                      </Form>
                    )
                  }
                </Formik>
              </div>
              }

              <div className={classes.commentArea}>
                <Typography variant="h6"><CommentIcon/>댓글</Typography>
                <PostDetailComments
                  isReportsModal={isReportsModal}
                  reportInfo={reportInfo}
                  eventInfo={eventInfo}
                  postId={isReportsModal ?
                    postInfo.targetCollection === "Posts" ? postInfo.targetId : postInfo.postId
                    : postId}
                />
              </div>

            </div>
          </DialogContent>
        </Dialog>
      </>
    ) :
    (
      (<div className={classes.loadingWrap}>
        <CircularProgress/>
      </div>)
    )
}
