import React, {useState, useContext, } from 'react';
import {useHistory} from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {TextField, CheckboxWithLabel} from 'formik-material-ui';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import * as Yup from 'yup';
import {Field, Formik, Form, ErrorMessage} from 'formik';
import * as Routes from '../../Constant/Routes';
import FirebaseContext from '../../Context/Firebase';
import LoadingOverlay from "../../Component/LoadingOverlay/Index";
import logo from "../../images/logo.png";
import {
  useLocation
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: '280px',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  phoneAuth: {
    float: 'right',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  errorMessage: {
    color: '#f5564a',
    margin: '0'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  MuiInputInput: {
    border: '3px solid #ccc'
  },
  whiteBtn: {
    marginBottom: '5px',
  },
  tlc: {
    textAlign: 'center',
    padding: '10px 0'
  },
  codeInput: {
    width: '100%',
    padding: '10px',
    textAlign: 'center',
    fontSize: '15px',
    border: '1px solid #ccc',
    borderRadius: '5px'
  }

}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function SignUp() {
  let query = useQuery();
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();


  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('이메일 형식으로 입력해주세요.')
      .max(50, '50자 이하로 입력해주세요.')
      .required('이메일을 입력해주세요.'),
    password: Yup.string()
      .min(6, '비밀번호는 6자 이상 입력해주세요.')
      .max(15, '비밀번호는 15자 이하로 입력해주세요.')
      .required('비밀번호를 입력해주세요.'),
    confirmPassword: Yup.string()
      .required('입력하신 비밀번호를 재입력해주세요.')
      .oneOf([Yup.ref('password')], '입력하신 비밀번호와 일치하지 않습니다.'),
    firstName: Yup.string()
      .required('성을 입력해주세요.')
      .max(20, '20자 이하로 입력해주세요.'),
    lastName: Yup.string()
      .required('이름을 입력해주세요.')
      .max(20, '20자 이하로 입력해주세요.'),
    serviceTerm: Yup.boolean()
      .oneOf([true], '서비스 이용약관에 동의해주세요.'),
    personalInformationTerm: Yup.boolean()
      .oneOf([true], '개인정보처리방침에 동의해주세요.'),

  });


  const onSubmit = (values) => {

    setIsLoading(true)
    const {email, password, firstName, lastName,} = values;

    firebase.auth().createUserWithEmailAndPassword(email, password)
      .catch(({code: errorCode}) => {
        setIsLoading(false)
        if (errorCode === 'auth/email-already-in-use') {
          window.alert('이미 가입된 이메일 계정이거나 탈퇴한 계정입니다.');
          return false;
        } else if (errorCode === 'auth/weak-password') {
          window.alert('입력하신 비밀번호가 적합하지 않습니다.');
          return false;
        } else if (errorCode === 'auth/invalid-email') {
          window.alert('입력하신 이메일 주소가 유효하지 않습니다.');
          return false;
        } else {
          window.alert('오류가 발생하였습니다. 관리자에게 문의해주세요');
          return false;
        }
      })
      .then((userCredentials) => {
        userCredentials.user.updateProfile({displayName: lastName})
          .then(() => {
            firebase.firestore().collection('OrganizerUsers').doc(email).set({
              userId: userCredentials.user.uid,
              firstName,
              lastName,
              email,
            })
              .then(() => {
                history.push(Routes.Root);
              })
              .catch((error) => {
                console.log('JoinError', error);
              });
          });
      })
      .catch(() => {
      });

  } //onSubmit End


  return (
    <>
      <Grid container component="main" className={classes.root}>
        <CssBaseline/>
        <Grid item xs={false} sm={false} md={7} className={classes.image}/>
        <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
              회원가입
            </Typography>
            <Formik
              initialValues={{
                email: query.get("email") || '',
                password: '',
                confirmPassword: '',
                firstName: '',
                lastName: '',
                serviceTerm: false,
                personalInformationTerm: false,
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {() => (
                <Form className={classes.form}>
                  <Field
                    disabled={false}
                    component={TextField}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="이메일"
                    name="email"
                  />
                  <Field
                    disabled={false}
                    component={TextField}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="password"
                    label="비밀번호"
                    type="password"
                  />
                  <Field
                    disabled={false}
                    component={TextField}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="confirmPassword"
                    label="비밀번호 재입력"
                    type="password"
                  />
                  <Field
                    disabled={false}
                    component={TextField}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="성"
                    name="firstName"
                  />
                  <Field
                    disabled={false}
                    component={TextField}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="이름"
                    name="lastName"
                  />


                  <div>
                    <Field
                      disabled={false}
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="serviceTerm"
                      Label={{label: '서비스이용약관 동의(필수)'}}
                    />
                    <p className={classes.errorMessage}>
                      <ErrorMessage name="serviceTerm"/>
                    </p>
                  </div>
                  <div>
                    <Field
                      disabled={false}
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="personalInformationTerm"
                      Label={{label: '개인정보처리방침 동의(필수)'}}
                    />
                    <p className={classes.errorMessage}>
                      <ErrorMessage name="personalInformationTerm"/>
                    </p>
                  </div>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    size="large"
                  >
                    회원가입
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </Grid>
      </Grid>
      {
        isLoading ?
          (<LoadingOverlay/>)
          : ''
      }
    </>
  );
}

