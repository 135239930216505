import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DaumPostcode from "react-daum-postcode";

export default function PostCodeModal(props) {
  const {
    isPostCodeModal,
    closePostCodeModal,
    settingAddress,
  } = props;
  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';
    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
      }
      fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
    }
    settingAddress(fullAddress);
    closePostCodeModal()
  }

  return (
    <div>

      <Dialog
        open={isPostCodeModal}
        onClose={closePostCodeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div style={{minWidth: '300px', width: '90vw', maxWidth: '500px', }}>
            <DaumPostcode
              onComplete={handleComplete}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
