import React, {useEffect, useState} from 'react';
import {useLocation, useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import AppMenuTitleEditable from "../../../../Component/AppMenuTitleEditable/Index";
import Button from "@material-ui/core/Button";

import {DashBoard} from '../../../../Constant/Routes'

import PostListView from "./PostListView";
import ReportListView from "./ReportListView";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
  eventInfoTitle: {
    paddingTop: '10px'
  },
  appMenuAddBtn: {
    float: 'right',
    marginLeft: '5px'
  },
  mb30: {
    margin: '15px 0 30px 0',
    height: '1px',
    backgroundColor: '#ccc',
    border: 'none'
  },
  tableHeader: {
    fontSize: '18px',
    fontWeight: 'bold'
  },
  noticeMangeBtn: {
    padding: '10px 20px',
    marginRight: '5px',
    background: '#fff',
    border: '1px solid #ccc',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  loadingWrap: {
    textAlign: 'center',
    width: '100%',
    padding: '10px 0'
  },
  appMenuTitleIcon: {
    position: 'relative',
    top: '3px',
    left: '5px',
    cursor: 'pointer'
  },
  editableInput: {
    width: '50%',
    padding: '5px 10px',
    display: 'inline'
  },


}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function PostAppManage(props) {
  const {eventInfo,} = props;
  const query = useQuery();
  const menuKey = query.get('key');
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [menuTitle, setMenuTitle] = useState('');
  const [tabNum, setTabNum] = useState(1);

  useEffect(() => {
    if (!eventInfo.menus[menuKey]) {
      history.push(DashBoard)
    } else {
      setMenuTitle(eventInfo.menus[menuKey].title);
      setIsLoading(false)
    }
  }, [])

  return !isLoading ?
    (
      <>
        <CssBaseline/>
        <Container maxWidth="lg" style={{overflow: 'auto'}}>
          <Typography variant="h5" className={classes.eventInfoTitle}>
            <AppMenuTitleEditable
              eventInfo={eventInfo}
              appMenuTitle={menuTitle}
              appMenuKey={menuKey}
            />
            <Button
              className={classes.appMenuAddBtn}
              variant="contained"
              color={tabNum === 2 ? "primary" : "default"}
              onClick={() => setTabNum(2)}
            >
              신고내역
            </Button>

            <Button
              className={classes.appMenuAddBtn}
              variant="contained"
              color={tabNum === 1 ? "primary" : "default"}
              onClick={() => setTabNum(1)}
            >
              게시글
            </Button>
          </Typography>
          <hr className={classes.mb30}/>
          {
            tabNum === 1 && (<PostListView
              eventInfo={eventInfo}
              menuKey={menuKey}
            />)
          }
          {
            tabNum === 2 && (<ReportListView
              eventInfo={eventInfo}
              menuKey={menuKey}
            />)
          }
        </Container>
      </>
    )
    : (
      <div className={classes.loadingWrap}>
        <CircularProgress/>
      </div>
    )

}
