import React, {useState, useEffect, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {DebounceInput} from 'react-debounce-input';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import * as Routes from '../../Constant/Routes';
import FirebaseContext from '../../Context/Firebase';
import AuthenticationContext from "../../Context/Authentication";
import {printDate} from "../../Utill/Functions";
import {eventState} from "../../Constant/SiteConfig";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  createEventBtn: {
    padding: '16px 30px',
  },
  mr15: {
    marginRight: '5px'
  },
  eventList: {
    border: '1px solid #dcdcdc',
    padding: '15px 30px',
    marginTop: '10px',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid #4050b5',
      background: '#4050b5',
      color: '#fff'
    }
  },
  eventState: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  loadingWrap: {
    textAlign: 'center',
    width: '100%'
  }
}));


export default function DashBoard() {
  const classes = useStyles();
  const history = useHistory()
  const firebase = useContext(FirebaseContext)
  const authUser = useContext(AuthenticationContext)
  const [isLoading, setIsLoading] = useState(true);
  const [eventCategory, setEventCategory] = useState('ALL');
  const [userEventList, setUserEventList] = useState([]);
  const [initUserEventList, setInitUserEventList] = useState([]);


  useEffect(() => {
    setIsLoading(true)
    const unsubscribe = firebase.firestore().collection('Events')
      .where('createdBy', '==', authUser)
      .orderBy('createdAt', 'desc')
      .onSnapshot((result) => {
        const resultData = [];
        result.forEach((doc) => {
          resultData.push(doc.data())
        })
        setUserEventList(resultData);
        setInitUserEventList(resultData);
        // checkEventDateExpire(resultData)
        setIsLoading(false)

      })
    return () => {
      unsubscribe();
    };
  }, [])
//만료된 행사 자동 업데이트 로직 .
  // const checkEventDateExpire = (eventData) => {
  //   const todayStartTime = new Date();
  //   todayStartTime.setHours(0);
  //   todayStartTime.setMinutes(0);
  //   todayStartTime.setSeconds(0);
  //   const now = todayStartTime.getTime()
  //   eventData.map((item) => {
  //     if (item.endAt.toDate().getTime() < now && item.state === 2) {
  //       firebase.firestore().collection('Events')
  //         .doc(item.id)
  //         .update({
  //           state: 3
  //         })
  //     } else if (item.endAt.toDate().getTime() > now && item.state === 3) {
  //       firebase.firestore().collection('Events')
  //         .doc(item.id)
  //         .update({
  //           state: 1
  //         })
  //     }
  //   })
  // }

  const eventCategoryChange = (event) => {
    const changedValue = event.target.value;

    if (changedValue === 'ALL') {
      setUserEventList(initUserEventList)
    } else {
      const categoryData = initUserEventList.filter((item) => item.state == changedValue);
      setUserEventList(categoryData);
    }
    setEventCategory(changedValue)
  };

  const handleSearch = (event) => {
    const keyword = event.target.value;
    const result = initUserEventList.filter((r) => {
      if (keyword == null) {
        return r;
      }
      if (r.title.includes(keyword)) {
        return r;
      }
    });
    if (eventCategory !== 'ALL') {
      setEventCategory('ALL');
    }
    setUserEventList(result);
  };
  const goEventDetail = (id) => {
    history.push(`${Routes.DashBoard}/${id}/main`)
  }
  return (
    <>
      <CssBaseline/>
      <Container maxWidth="lg" style={{minHeight: '80vh'}}>
        <Box mt={5}>
          <Grid container>
            <Grid item xs={12} md={12} lg={12} style={{textAlign: 'right', marginBottom: '10px'}}>
              <Button
                variant="contained"
                color="primary"
                className={classes.createEventBtn}
                onClick={() => history.push(Routes.CreateEvent)}
                size="large"
              >
                행사 생성하기
              </Button>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Typography variant="h4">
                나의 행사
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={6} style={{textAlign: 'right'}}>
              <FormControl variant="outlined" className={classes.mr15}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={eventCategory}
                  onChange={eventCategoryChange}
                >
                  <MenuItem value="ALL">전체보기</MenuItem>
                  <MenuItem value="1">편집중</MenuItem>
                  <MenuItem value="2">게시중</MenuItem>
                </Select>
              </FormControl>
              <DebounceInput
                variant="outlined"
                element={TextField}
                minLength={1}
                type="search"
                debounceTimeout={500}
                placeholder="검색어"
                onChange={handleSearch}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mt={5}>
          {isLoading ?
            <div className={classes.loadingWrap}>
              <CircularProgress/>
            </div>
            : userEventList && userEventList.length > 0
              ?
              userEventList.map((item) => (
                <Grid container className={classes.eventList} key={item.id} onClick={() => goEventDetail(item.id)}>
                  <Grid item xs={10} md={11} lg={11}>
                    <Typography variant="h6">
                      {item.title}
                    </Typography>
                    <p>
                      {printDate(item.startAt.toDate())} ~ {printDate(item.endAt.toDate())}
                    </p>
                  </Grid>
                  <Grid item xs={1} md={1} lg={1} className={classes.eventState}>
                    <Typography variant="h6">
                      {eventState[item.state]}
                    </Typography>
                  </Grid>
                </Grid>
              ))
              : (<div style={{textAlign: 'center', fontSize: '16px'}}>해당 데이터가 없습니다.</div>)
          }
        </Box>
      </Container>
    </>
  )
}
