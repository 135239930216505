import React, { useContext, useState, useRef } from 'react';
import Box from '@material-ui/core/Box';
import { Field, Form, Formik } from 'formik';
import Typography from '@material-ui/core/Typography';
import { TextField } from 'formik-material-ui';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import Grid from '@material-ui/core/Grid';
import { Editor } from '@tinymce/tinymce-react';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import * as Functions from '../../../Utill/Functions';
import { makeStyles } from '@material-ui/core/styles';
import LoadingOverlay from '../../../Component/LoadingOverlay/Index';
import FirebaseContext from '../../../Context/Firebase';
import { tinyApiKey } from '../../../Constant/SiteConfig';
import FileUploadModule from '../../../Component/EventMainImageUploadModule/Index';
import { getRandomKey } from '../../../Utill/Functions';

const useStyles = makeStyles(() => ({
  button: {
    float: 'right',
    padding: '10px 50px',
  },
  mt30: {
    marginTop: '30px',
  },

  errorMsg: {
    color: '#f44638',
  },
  ErrorTextEditor: {
    border: '1px solid #f44638',
    marginBottom: '10px',
  },
}));

export default function EventBasicInfoSummary(props) {
  const { eventInfo } = props;
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const [isLoading, setIsLoading] = useState(false);
  const formValues = {
    title: eventInfo.title,
    description: eventInfo.description,
    startDate: moment(new Date(eventInfo.startAt.seconds * 1000)),
    endDate: moment(new Date(eventInfo.endAt.seconds * 1000)),
    startTime: moment(new Date(eventInfo.startAt.seconds * 1000)),
    endTime: moment(new Date(eventInfo.endAt.seconds * 1000)),
  };

  //파일업로드관련 로직 시작
  const [selectedFile, setSelectedFile] = useState(null);
  const deletePrevUploadImg = useRef(false);

  const fileUploadHandler = () => {
    return new Promise((resolve) => {
      const RandomText = getRandomKey();
      const fileName = `${eventInfo.id}_${RandomText}`;
      const storageRef = firebase.storage().ref(`images/${fileName}`);
      const task = storageRef.put(selectedFile);
      task.on(
        'state_changed',
        function progress(snapshot) {
          const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(percentage);
        },
        function error(err) {
          console.log(err);
        },
        function complete() {
          resolve(fileName);
        },
      );
    });
  };

  //파일업로드관련 로직 끝

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .min(1, '1자 이상 입력해주세요')
      .max(50, '50자 이하로 입력해주세요.')
      .required('행사명을 입력해주세요.'),
    description: Yup.string()
      .max(30000, '30000자 이하로 입력해주세요.')
      .required('행사소개를 입력해주세요.'),
    startDate: Yup.mixed().test(
      'isBefore',
      '행사 시작날짜는 행사 종료날짜 이전으로 선택해 주세요.',
      function isBefore(value) {
        return value.isSameOrBefore(this.parent.endDate, 'day');
      },
    ),
    endDate: Yup.mixed().test(
      'isAfter',
      '행사 종료날짜는 행사 시작날짜 이후로 선택해 주세요.',
      function isBefore(value) {
        return value.isSameOrAfter(this.parent.startDate, 'day');
      },
    ),
  });

  const onSubmit = async (values) => {
    setIsLoading(true);

    let uploadedFileName = '';
    let imagePath = eventInfo.imagePath;

    if (deletePrevUploadImg.current) {
      imagePath = '';
      try {
        firebase.storage().ref(eventInfo.imagePath).delete();
      } catch (e) {
        console.log(e);
      }
    }

    if (selectedFile !== null) {
      uploadedFileName = await fileUploadHandler();
      imagePath = `images/${uploadedFileName}`;
    }

    const { title, description, startDate, endDate, startTime, endTime } = values;

    const startAt = new Date(
      startDate.toDate().getFullYear(),
      startDate.toDate().getMonth(),
      startDate.toDate().getDate(),
      startTime.toDate().getHours(),
      startTime.toDate().getMinutes(),
    );
    const endAt = new Date(
      endDate.toDate().getFullYear(),
      endDate.toDate().getMonth(),
      endDate.toDate().getDate(),
      endTime.toDate().getHours(),
      endTime.toDate().getMinutes(),
    );

    firebase
      .firestore()
      .collection('Events')
      .doc(eventInfo.id)
      .update({
        title: title,
        keywords: Functions.getKeywords(title),
        description: description,
        startAt: startAt,
        endAt: endAt,
        imagePath: imagePath,
      })
      .then(() => {
        setIsLoading(false);
        window.scrollTo(0, 0);
      });
  };

  return (
    <>
      <Box mt={1} mb={1}>
        <Formik initialValues={formValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ values, errors, setFieldValue }) => (
            <Form className={classes.form}>
              <div>
                <Typography variant="subtitle1">행사명</Typography>
                <Field
                  disabled={false}
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="행사명을 입력해주세요."
                  name="title"
                />
                <Typography variant="h6" className={classes.mt30}>
                  행사 메인이미지
                </Typography>
                <FileUploadModule
                  deletePrevUploadImg={deletePrevUploadImg}
                  imagePath={eventInfo.imagePath}
                  setSelectedFile={setSelectedFile}
                />

                <Typography variant="subtitle1" className={classes.mt30}>
                  행사 일정
                </Typography>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="ko">
                  <>
                    <Grid container spacing={1}>
                      <Grid item>
                        <DatePicker
                          disableToolbar
                          variant="dialog"
                          format="YYYY. MM. DD(dd)"
                          margin="normal"
                          id="date-picker-from"
                          label="행사시작 날짜"
                          name="startDate"
                          value={values.startDate}
                          onChange={(value) => setFieldValue('startDate', value)}
                          inputVariant="outlined"
                        />
                      </Grid>
                      <Grid item>
                        <TimePicker
                          label="행사 시작시각"
                          inputVariant="outlined"
                          mask="__:__ _M"
                          margin="normal"
                          name="startTime"
                          value={values.startTime}
                          onChange={(value) => setFieldValue('startTime', value)}
                        />
                      </Grid>
                    </Grid>
                    {errors.startDate && <p className={classes.errorMsg}>{errors.startDate}</p>}
                    <Grid container spacing={1}>
                      <Grid item>
                        <DatePicker
                          disableToolbar
                          variant="dialog"
                          format="YYYY. MM. DD(dd)"
                          margin="normal"
                          inputVariant="outlined"
                          id="date-picker-to"
                          label="행사종료 날짜"
                          name="endDate"
                          value={values.endDate}
                          onChange={(value) => setFieldValue('endDate', value)}
                        />
                      </Grid>
                      <Grid item>
                        <TimePicker
                          label="행사 종료시각"
                          inputVariant="outlined"
                          margin="normal"
                          mask="__:__ _M"
                          name="endTime"
                          value={values.endTime}
                          onChange={(value) => setFieldValue('endTime', value)}
                        />
                      </Grid>
                    </Grid>
                    {errors.endDate && <p className={classes.errorMsg}>{errors.endDate} </p>}
                  </>
                </MuiPickersUtilsProvider>
                <Typography variant="subtitle1" className={classes.mt30}>
                  행사 소개
                </Typography>
                <br />
                <div className={errors.description ? classes.ErrorTextEditor : ''}>
                  <Editor
                    branding={false}
                    apiKey={tinyApiKey}
                    init={{
                      height: 400,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image',
                        'charmap print preview anchor help',
                        'searchreplace visualblocks code',
                        'insertdatetime media table paste wordcount',
                      ],
                      toolbar:
                        'undo redo | formatselect | bold forecolor backcolor italic | \
                          alignleft aligncenter alignright | \
                          bullist numlist outdent indent',
                      block_formats:
                        '본문=p; 제목 1=h1; 제목 2=h2; 제목 3=h3; 제목 4=h4; 제목 5=h5; 제목 6=h6;',
                    }}
                    value={values.description}
                    onEditorChange={(value) => setFieldValue('description', value)}
                  />
                </div>
                {errors.description && <p className={classes.errorMsg}>{errors.description} </p>}
              </div>
              <Box mt={4}>
                <Grid container>
                  <Grid item xs={12} md={12} lg={12} className={classes.progressBtn}>
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      저장
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      {isLoading ? <LoadingOverlay /> : ''}
    </>
  );
}
