import React, { Fragment, useState, useEffect, useContext } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useHistory, useLocation } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import AgendaManageModal from './AgendaManageModal';
import FirebaseContext from '../../../../Context/Firebase';
import CircularProgress from '@material-ui/core/CircularProgress';
import { printDate, printTime } from '../../../../Utill/Functions';
import AppMenuTitleEditable from '../../../../Component/AppMenuTitleEditable/Index';
import { DashBoard } from '../../../../Constant/Routes';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
  eventInfoTitle: {
    paddingTop: '10px',
  },
  appMenuAddBtn: {
    float: 'right',
  },
  mb30: {
    margin: '15px 0 30px 0',
    height: '1px',
    backgroundColor: '#ccc',
    border: 'none',
  },
  tableHeader: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  noticeMangeBtn: {
    padding: '10px 20px',
    marginRight: '5px',
    background: '#fff',
    border: '1px solid #ccc',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  loadingWrap: {
    textAlign: 'center',
    width: '100%',
    padding: '10px 0',
  },
  appMenuTitleIcon: {
    position: 'relative',
    top: '3px',
    left: '5px',
    cursor: 'pointer',
  },
  editableInput: {
    width: '50%',
    padding: '5px 10px',
    display: 'inline',
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function AgendaAppManage(props) {
  const { eventInfo } = props;
  const query = useQuery();
  const menuKey = query.get('key');
  const history = useHistory();
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const [isModal, setIsModal] = useState(false);
  const [menuDatas, setMenuDatas] = useState([]);
  const [itemInfo, setItemInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [menuTitle, setMenuTitle] = useState('');
  useEffect(() => {
    setIsLoading(true);
    eventInfo.menus[menuKey] === undefined
      ? history.push(DashBoard)
      : setMenuTitle(eventInfo.menus[menuKey].title);
    const unsubscribe = firebase
      .firestore()
      .collection('Events')
      .doc(eventInfo.id)
      .collection('Agendas')
      .where('menuId', '==', menuKey)
      .orderBy('startAt', 'asc')
      .onSnapshot((docResult) => {
        const resultData = [];
        docResult.forEach((doc) => {
          resultData.push({ id: doc.id, ...doc.data() });
        });
        setMenuDatas(resultData);
        setIsLoading(false);
      });
    return () => {
      unsubscribe();
    };
  }, []);

  const openAddModal = () => {
    setIsModal(true);
  };

  const closeModalWithoutConfirm = () => {
    setIsModal(false);
    setItemInfo(null);
  };

  const editItem = (targetItem) => {
    setItemInfo(targetItem);
    setIsModal(true);
  };
  const deleteItem = (item) => {
    if (window.confirm('해당 항목을 삭제하시겠습니까?')) {
      firebase
        .firestore()
        .collection('Events')
        .doc(eventInfo.id)
        .collection('Agendas')
        .doc(item.id)
        .delete()
        .then(() => {
          console.log('deleted..');
        });
    }
  };

  return !isLoading ? (
    <>
      <CssBaseline />
      <Container maxWidth="lg" style={{ overflow: 'auto' }}>
        <Typography variant="h5" className={classes.eventInfoTitle}>
          <AppMenuTitleEditable
            eventInfo={eventInfo}
            appMenuTitle={menuTitle}
            appMenuKey={menuKey}
          />
          <Button
            className={classes.appMenuAddBtn}
            variant="contained"
            color="primary"
            onClick={openAddModal}
          >
            세션 추가
          </Button>
        </Typography>
        <hr className={classes.mb30} />
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '30%' }} className={classes.tableHeader}>
                  세션명
                </TableCell>
                <TableCell className={classes.tableHeader} align="left">
                  날짜
                </TableCell>
                <TableCell className={classes.tableHeader} align="left">
                  시간
                </TableCell>
                <TableCell className={classes.tableHeader} align="center">
                  관리
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {menuDatas && menuDatas.length > 0 ? (
                menuDatas.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {item.title
                        .substr(0, 20)
                        .split('\\n')
                        .map((line, index) => (
                          <Fragment key={`${index}`}>
                            {index !== 0 && !!line.length && <br />}
                            <span>{line}</span>
                          </Fragment>
                        ))}
                      {item.title.length > 20 ? <span> ...</span> : ''}
                    </TableCell>
                    <TableCell align="left">{printDate(item.startAt.toDate())}</TableCell>
                    <TableCell align="left">
                      {`${printTime(item.startAt.toDate())} ~${
                        !!item.endAt ? ` ${printTime(item.endAt.toDate())}` : ''
                      }`}
                    </TableCell>

                    <TableCell align="center">
                      <button className={classes.noticeMangeBtn} onClick={() => editItem(item)}>
                        편집
                      </button>
                      <button className={classes.noticeMangeBtn} onClick={() => deleteItem(item)}>
                        삭제
                      </button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                    해당 데이터가 없습니다.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>

      {isModal && (
        <AgendaManageModal
          menuId={menuKey}
          menuDatas={menuDatas}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          itemInfo={itemInfo}
          isModal={isModal}
          eventInfo={eventInfo}
          closeModalWithoutConfirm={closeModalWithoutConfirm}
        />
      )}
    </>
  ) : (
    <div className={classes.loadingWrap}>
      <CircularProgress />
    </div>
  );
}
