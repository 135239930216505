import React from 'react';
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import {DebounceInput} from 'react-debounce-input';

const useStyles = makeStyles(() => ({
  optionBtn: {
    margin: '10px 0 0 10px'
  },
  outsideLinkWrap: {
    border: '1px solid #ccc',
    padding: '10px',
    borderRadius: '5px'
  }

}));

export default function OutsideLinkAddForm(props) {
  const {
    closeOutsideLinkInsertForm,
    onChange,
    onCreate,
    url,
    title,
    isOutsideLinkEditMode,
    onEditComplete
  } = props;

  const classes = useStyles();
  return (
    <>
      <Box mt={2} mb={2} className={classes.outsideLinkWrap}>
        <Grid container>
          <Grid item xs={4}>
            <DebounceInput
              element={TextField}
              debounceTimeout={500}
              label="링크명"
              type="text"
              name="title"
              value={title}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={8}>
            <DebounceInput
              element={TextField}
              debounceTimeout={500}
              label="URL(http:// 를 포함해주세요)"
              placeholder="http://를 포함해주세요"
              type="text"
              name="url"
              value={url}
              onChange={onChange}
              fullWidth
            />
          </Grid>

        </Grid>


        <div style={{textAlign: 'right'}}>
          <Button
            color="default"
            variant="contained"
            onClick={closeOutsideLinkInsertForm}
            className={classes.optionBtn}>
            취소
          </Button>
          {isOutsideLinkEditMode
            ? (
              <Button
                color="secondary"
                variant="contained"
                className={classes.optionBtn}
                onClick={onEditComplete}
              >
                수정
              </Button>
            )
            : (
              <Button
                color="secondary"
                variant="contained"
                className={classes.optionBtn}
                onClick={onCreate}
              >
                추가
              </Button>
            )
          }


        </div>
      </Box>
    </>
  )

}
