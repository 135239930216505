import React, {useState, useContext,} from 'react';
import {useHistory} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {TextField} from 'formik-material-ui';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import * as Yup from 'yup';
import {Field, Formik, Form} from 'formik';
import * as Routes from '../../Constant/Routes';
import FirebaseContext from '../../Context/Firebase';
import SubLink from "./SubLink";
import JoinPageLink from "./JoinPageLink";
import LoadingOverlay from "../../Component/LoadingOverlay/Index";
import logo from '../../images/logo.png';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: '280px',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  backBtn: {
    padding: '15px 30px',
    background: 'none',
    border: '1px solid #ccc',
    borderRadius: '8px',
    color:'#737171',
    cursor:'pointer',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login(props) {
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isClient, setClient] = useState(false)
  const [needProfileUpdate, setNeedProfileUpdate] = useState(false)
  const history = useHistory();


  const checkEmailAccountValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('이메일 형식으로 입력해주세요.')
      .required('이메일을 입력해주세요.'),

  });

  const loginValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('이메일 형식으로 입력해주세요.')
      .required('이메일을 입력해주세요.'),
    password: Yup.string()
      .required('비밀번호를 입력해주세요')
  });


  const checkEmailAccountOnSubmit = (values) => {
    setIsLoading(true)
    const Users = firebase.firestore().collection('Users').doc(values.email).get();
    const OrganizerUsers = firebase.firestore().collection('OrganizerUsers').doc(values.email).get();

    Promise.all([Users, OrganizerUsers])
      .then(([UsersDoc, OrganizerUserDoc]) => {
        if (OrganizerUserDoc.exists) {
          setClient(true)
          setIsLoading(false)
        } else if (UsersDoc.exists) {
          setClient(true)
          setNeedProfileUpdate(true)
          setIsLoading(false)
        } else {
          alert('입력하신 계정이 존재하지 않습니다.\n회원가입 페이지로 이동합니다.')
          history.push(`${Routes.SignUp}?email=${values.email}`)

        }
      })

  };

  const onSubmit = (values) => {
    setIsLoading(true);
    const {email, password} = values;

    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        firebase.auth().signInWithEmailAndPassword(email, password)
          .then(() => {
            if (needProfileUpdate) {
              firebase.firestore().collection('Users')
                .doc(email)
                .get()
                .then((doc) => {
                  firebase.firestore().collection('OrganizerUsers')
                    .doc(email)
                    .set({
                      email: email,
                      firstName: doc.data().firstName,
                      lastName: doc.data().lastName,
                    }).then(() => {
                    history.push(Routes.DashBoard)
                  })
                })
            }
          }).catch(() => {
          setIsLoading(false);
          window.alert('비밀번호가 유효하지 않습니다.');
        })
      })
  };

  const checkedAccountInitvalue = {
    email: '',
    password: ''
  }

  return (
    <>
      <Grid container component="main" className={classes.root}>

        <CssBaseline/>
        <Grid item xs={false} sm={false} md={7} className={classes.image}/>
        <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square>

          <div className={classes.paper}>
            {
              isClient &&
              <div style={{textAlign: 'left', width: '100%'}}>
                <button
                  className={classes.backBtn}
                  onClick={() => setClient(false)}
                >
                  처음으로
                </button>
              </div>
            }

            <div style={{textAlign: 'center'}}>
              <Typography component="h1" variant="h5" style={{paddingTop: '100px'}}>
                Nolla에 오신것을<br/> 환영합니다.
              </Typography>
            </div>


            <Formik
              initialValues={checkedAccountInitvalue}
              validationSchema={isClient ? loginValidationSchema : checkEmailAccountValidationSchema}
              onSubmit={isClient ? onSubmit : checkEmailAccountOnSubmit}
            >
              {() => (
                <Form className={classes.form}>
                  <Field
                    disabled={isClient}
                    component={TextField}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="이메일을 입력해주세요"
                    name="email"
                  />

                  {
                    isClient &&
                    <Field
                      disabled={false}
                      component={TextField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="비밀번호를 입력해주세요"
                      name="password"
                      type="password"
                    />

                  }
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    size="large"
                  >
                    {isClient ? <>로그인</> : <>다음</>

                    }
                  </Button>
                </Form>
              )}
            </Formik>


            <SubLink
              viewComponent={props.viewComponent}
              setViewComponent={props.setViewComponent}
            />
          </div>
          <JoinPageLink/>
        </Grid>
      </Grid>
      {isLoading ? (<LoadingOverlay/>) : ''}
    </>
  );
}

