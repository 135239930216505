import React, {useState, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  TextField,
  CheckboxWithLabel,
} from 'formik-material-ui';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import * as Yup from 'yup';
import {Field, Formik, Form} from 'formik';
import * as Routes from '../../Constant/Routes';
import FirebaseContext from '../../Context/Firebase';
import AuthenticationContext from '../../Context/Authentication';

import LoadingOverlay from "../../Component/LoadingOverlay/Index";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  textCenter: {
    textAlign: 'center'
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function FindPassword(props) {
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthenticationContext);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('비밀번호를 입력해주세요'),

  });


  const deleteAuth = () => {
    firebase.firestore().collection('OrganizerUsers').doc(authUser).delete()
    firebase.firestore().collection('Users').doc(authUser).delete()
    firebase.auth().currentUser.delete().then(() => {
      alert('계정이 정상적으로 삭제되었습니다.')
      history.push(Routes.Root)
    })
  }

  const onSubmit = (values) => {
    setIsLoading(true);
    const {
      password, R1, R2, R3, R4
    } = values;

    const withdrawalReasonArr = [];
    if (R1) {
      withdrawalReasonArr.push(withdrawalReason[0].label);
    }
    if (R2) {
      withdrawalReasonArr.push(withdrawalReason[1].label);
    }
    if (R3) {
      withdrawalReasonArr.push(withdrawalReason[2].label);
    }
    if (R4) {
      withdrawalReasonArr.push(withdrawalReason[3].label);
    }


    firebase.auth()
      .signInWithEmailAndPassword(authUser, password)
      .then(() => {

        firebase.firestore().collection('Events')
          .where('createdBy', '==', authUser)
          .where('state', '==', 2)
          .get()
          .then((doc) => {
            if (doc.size >= 1) {
              alert('진행중인 행사가 있을경우 탈퇴가 불가능합니다.')
              setIsLoading(false);
              return false;
            } else {

              firebase.firestore().collection('Users')
                .doc(authUser)
                .get()
                .then((r) => {
                  firebase.firestore().collection('WithdrawalUsers')
                    .add({
                      email: authUser,
                      attendedEvents: r.data() === undefined ? '' : r.data().attendedEvents,
                      withdrawalReason: withdrawalReasonArr,
                      withdrawAt: new Date(),
                    })
                    .then(() => {
                      deleteAuth()
                    })
                })
                .catch(() => {
                  firebase.firestore().collection('WithdrawalUsers')
                    .add({
                      email: authUser,
                      withdrawalReason: withdrawalReasonArr,
                      withdrawAt: new Date(),
                    })
                    .then(() => {
                      deleteAuth()
                    })
                })


            }
          })

      }).catch(() => {
      window.alert('현재 비밀번호가 일치하지 않습니다.');
      setIsLoading(false);
    });
  };

  return (
    <>
      <Grid container component="main" className={classes.root}>
        <CssBaseline/>
        <Grid item xs={false} sm={false} md={7} className={classes.image}/>
        <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
              회원탈퇴
            </Typography>
            <br/>
            <Typography variant="body2" className={classes.textCenter}>
              회원탈퇴를 하시면 모든 정보가 삭제되며, 다시 복구할 수가 없습니다.<br/>
              신중히 판단하시고 실행에 옮기기 바랍니다.<br/>
              회원 탈퇴 사유를 선택해 주시면 시스템 보완에 많은 도움이 되겠습니다.<br/>
              중복 선택이 가능합니다. 감사합니다.
            </Typography>
            <Formik
              initialValues={{
                password: '',
                R1: false,
                R2: false,
                R3: false,
                R4: false,
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {() => (
                <Form className={classes.form}>

                  <div>
                    {withdrawalReason.map((option) => (
                      <Field
                        key={option.value}
                        disabled={false}
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name={option.value}
                        Label={{label: option.label}}
                      />
                    ))}
                  </div>
                  <Field
                    disabled={false}
                    component={TextField}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="현재 비밀번호를 입력해주세요."
                    type="password"
                    name="password"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    size="large"
                  >

                    계정탈퇴
                  </Button>


                </Form>
              )}
            </Formik>

          </div>
        </Grid>
      </Grid>
      {isLoading ? (<LoadingOverlay/>) : ''}
    </>
  );
}

const withdrawalReason = [
  {
    value: 'R1',
    label: '내 행사에 효과없음',
  },

  {
    value: 'R2',
    label: '사용방법이 어려움',
  },
  {
    value: 'R3',
    label: '사용료가 부담스러움',
  },
  {
    value: 'R4',
    label: '다른제품으로 옮겨감',
  },


];
