import React, {useContext, useEffect, useState} from 'react';
import FirebaseContext from '../../../../Context/Firebase';
import {useLocation, useHistory} from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";
import {DashBoard} from "../../../../Constant/Routes";
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import AppMenuTitleEditable from "../../../../Component/AppMenuTitleEditable/Index";
import Button from "@material-ui/core/Button";
import Tooltip from '@material-ui/core/Tooltip';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import useToast from "../../../../Hook/useToast";
import WebListManageModal from "./WebListManageModal";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
} from "react-grid-dnd";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
  eventInfoTitle: {
    paddingTop: '10px'
  },
  appMenuAddBtn: {
    float: 'right',
    marginLeft: '5px'
  },
  mb30: {
    margin: '15px 0 0 0',
    height: '1px',
    backgroundColor: '#ccc',
    border: 'none'
  },
  tableHeader: {
    fontSize: '18px',
    fontWeight: 'bold',
  },

  loadingWrap: {
    textAlign: 'center',
    width: '100%',
    padding: '10px 0'
  },
  webLinkMangeBtn: {
    padding: '10px 20px',
    marginRight: '5px',
    background: '#fff',
    border: '1px solid #ccc',
    borderRadius: '5px',
    cursor: 'pointer'
  },

  imgContent: {
    display: 'block',
    maxWidth: '100px',
    maxHeight: '50px',
    width: 'auto',
    height: 'auto',
    margin: '0 auto'
  }

}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function WebListAppManage(props) {
  const {eventInfo,} = props;
  const firebase = useContext(FirebaseContext);
  const query = useQuery();
  const menuKey = query.get('key');
  const history = useHistory();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [menuTitle, setMenuTitle] = useState('');
  const [webListData, setWebListData] = useState([]);
  const [isWebListAddModal, setIsWebListAddModal] = useState(false)
  const [webListInfo, setWebListInfo] = useState(null)
  const {setShowToast, renderToast} = useToast({message: '해당 리스트가 삭제되었습니다.', onClose: () => console.log('onClose')})


  useEffect(() => {
    if (!eventInfo.menus[menuKey]) {
      history.push(DashBoard)
    } else {
      const unsubscribe = firebase.firestore().collection('Events')
        .doc(eventInfo.id)
        .collection('WebLinks')
        .where('menuId', '==', menuKey)
        .onSnapshot((docResult) => {
          const resultData = [];
          docResult.forEach((doc) => {
            resultData.push(doc.data())
          })
          const arrangedData = resultData.sort((a, b) => a.order - b.order);
          setWebListData(arrangedData)

          setMenuTitle(eventInfo.menus[menuKey].title)
          setIsLoading(false)
        })
      return () => {
        unsubscribe();
      }
    }

  }, [])

  const openWebListManageModal = () => {
    setIsWebListAddModal(true)
  }
  const closeWebListManageModal = () => {
    setIsWebListAddModal(false)
    setWebListInfo(null)
  }

  const editListItem = (Item) => {
    setWebListInfo(Item)
    setIsWebListAddModal(true)
  }
  const deleteListItem = (itemId) => {
    if (window.confirm('해당 리스트를 삭제하시겠습니까?')) {
      firebase.firestore().collection('Events')
        .doc(eventInfo.id)
        .collection('WebLinks')
        .doc(itemId)
        .delete()
        .then(() => {
          setShowToast(true);
        })
    }
  }


  const dragOnChange = (sourceId, sourceIndex, targetIndex,) => {
    if (webListData[targetIndex] === undefined ||
      webListData[targetIndex].order === undefined ||
      webListData[sourceIndex] === undefined ||
      webListData[sourceIndex].order === undefined) {
      return false
    }
    let prevOrder;
    let currentOrder;
    let resultOrder;
    if (sourceIndex > targetIndex) {
      prevOrder = targetIndex === 0 ? 0 : webListData[targetIndex - 1].order;
      currentOrder = webListData[targetIndex].order;
    } else {
      prevOrder = webListData[targetIndex].order;
      currentOrder = webListData[targetIndex + 1] ? webListData[targetIndex + 1].order : (webListData[targetIndex].order) + 1
    }
    resultOrder = (prevOrder + currentOrder) / 2;
    firebase.firestore().collection('Events')
      .doc(eventInfo.id)
      .collection('WebLinks')
      .doc(webListData[sourceIndex].id)
      .update({
        order: resultOrder
      })

  }

  return !isLoading ? (
      <>
        {renderToast()}
        <CssBaseline/>
        <Container maxWidth="lg" style={{overflow: 'auto'}}>
          <Typography variant="h5" className={classes.eventInfoTitle}>
            <AppMenuTitleEditable
              eventInfo={eventInfo}
              appMenuTitle={menuTitle}
              appMenuKey={menuKey}
            />
            <Button
              className={classes.appMenuAddBtn}
              variant="contained"
              color="primary"
              onClick={openWebListManageModal}
            >
              링크 추가
            </Button>
          </Typography>
          <hr className={classes.mb30}/>


          <Box mt={3} mb={3}>
            <Grid container spacing={1}>
              <Grid container item xs={1} justify="center" className={classes.tableHeader}>순서</Grid>
              <Grid container item xs={2} justify="center" className={classes.tableHeader}>썸네일</Grid>
              <Grid container item xs={2} className={classes.tableHeader}>제목</Grid>
              <Grid container item xs={5} className={classes.tableHeader}>설명</Grid>
              <Grid container item xs={2} justify="center" className={classes.tableHeader}>관리</Grid>
            </Grid>
          </Box>

          <GridContextProvider onChange={dragOnChange}>
            <GridDropZone
              boxesPerRow={1}
              rowHeight={63}
              style={{height: webListData.length * 63, overflow: 'hidden',}}
              id="items"
            >
              {webListData && webListData.length > 0 ?
                webListData.map((item) => (
                  <GridItem key={item.id}>
                    <Grid container spacing={1} style={{borderBottom: '1px solid #eaeaea', marginTop: '4px'}}>
                      <Grid container item xs={1} justify="center">
                        <Tooltip title="드래그하여 순서변경" placement="bottom">
                          <DragIndicatorIcon style={{cursor: 'move'}}/>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={2}>
                        {
                          item.imgUrl &&
                          <img
                            className={classes.imgContent}
                            src={item.imgUrl}
                            alt={item.title}
                          />
                        }
                      </Grid>
                      <Grid item xs={2}>
                        {item.title.length < 10 ? item.title : item.title.substr(0, 10) + '...'}
                      </Grid>
                      <Grid item xs={5}>
                        {item.description.length < 35 ? item.description : item.description.substr(0, 35) + '...'}
                      </Grid>
                      <Grid item xs={2} style={{textAlign: 'center'}}>
                        <button className={classes.webLinkMangeBtn} onClick={() => editListItem(item)}>편집</button>
                        <button className={classes.webLinkMangeBtn} onClick={() => deleteListItem(item.id)}>삭제 </button>
                      </Grid>
                    </Grid>
                  </GridItem>
                ))
                : (
                  <Grid container>
                    <Grid item xs={12}>
                      관련 데이터가 없습니다.
                    </Grid>
                  </Grid>
                )
              }
            </GridDropZone>
          </GridContextProvider>

        </Container>
        {
          isWebListAddModal &&
          <WebListManageModal
            orderCount={webListData.length}
            menuId={menuKey}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            webListInfo={webListInfo}
            isModal={isWebListAddModal}
            closeModal={closeWebListManageModal}
            eventInfo={eventInfo}
          />
        }
      </>
    )
    : (
      <div className={classes.loadingWrap}>
        <CircularProgress/>
      </div>
    );
};
