import React from 'react';
import Box from "@material-ui/core/Box";
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import docSvg from '../../../../images/doc.svg';
import jpgSvg from '../../../../images/jpg.svg';
import pngSvg from '../../../../images/png.svg';
import pdfSvg from '../../../../images/pdf.svg';
import pptSvg from '../../../../images/ppt.svg';
import xlsSvg from '../../../../images/xls.svg';
import basicSvg from '../../../../images/basic.svg';

import {
  allowedWordFileTypes,
  allowedExcelFileTypes,
  allowedPptFileTypes,
  allowedPdfFileTypes,
  allowedJpgFileTypes,
  allowedPngFileTypes
} from '../../../../Constant/SiteConfig';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    marginTop: '7px'
  },
  tal: {
    textAlign: 'left',
    fontSize: '16px',
    paddingLeft: '10px!important'
  },
  tac: {
    textAlign: 'center',
    fontSize: '15px'
  },
  tar: {
    textAlign: 'right',
    paddingRight: '10px!important'
  },
  listEditBtn: {
    display: 'inline-block',
    cursor: 'pointer',
    "&:hover": {
      fontWeight: 'bold'
    }

  }
}));
export default function FileUploadList(props) {
  const {uploadFileObject, fileUploadOnRemove,} = props;
  const classes = useStyles();


  return (
    <Box mt={2}>
      {Object.values(uploadFileObject).map((item) => (
          <Grid container className={classes.root} spacing={1} key={item.id}>
            <Grid
              item xs={10}
              className={classes.tal}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img
                style={{
                  width: '30px',
                  marginRight: '4px'
                }}
                alt=""
                src={
                  allowedPngFileTypes.includes(item.extension) ? pngSvg :
                  allowedJpgFileTypes.includes(item.extension) ? jpgSvg :
                  allowedPdfFileTypes.includes(item.extension) ? pdfSvg :
                  allowedExcelFileTypes.includes(item.extension) ? xlsSvg :
                  allowedPptFileTypes.includes(item.extension) ? pptSvg :
                  allowedWordFileTypes.includes(item.extension) ? docSvg : basicSvg
                }/>
              {item.title}
            </Grid>
            <Grid
              item xs={2}
              style={{
                textAlign: 'center',
                paddingTop: '8px'
              }}
            >
              <p
                className={classes.listEditBtn}
                onClick={() => fileUploadOnRemove(item)}
              >삭제
              </p>

            </Grid>
          </Grid>
        )
      )}
    </Box>
  )

}
