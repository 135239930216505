import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as Routes from '../../Constant/Routes';
import {useHistory} from 'react-router-dom';

const JoinPageLink = () => {
  const history = useHistory();
  const goSignUp = () => {
    history.push(Routes.SignUp)
  }
  return (
    <>
      <Box mt={5}>
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
        >
          놀라
        </Typography>

        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
        >
          아직 회원이 아니신가요?
          <Button onClick={() => goSignUp()}>
            회원가입
          </Button>
        </Typography>


      </Box>
    </>
  )
}


export default JoinPageLink;
