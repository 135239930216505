import React, {useState,} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import EventBasicInfoSummary from './EventBasicInfoSummary';
import EventBasicInfoPlace from './EventBasicInfoPlace';
import EventBasicInfoProperty from './EventBasicInfoProperty';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  eventInfoTitle: {
    paddingTop: '10px'
  },
  mb30: {
    margin: '15px 0 30px 0',
    height: '1px',
    backgroundColor: '#ccc',
    border: 'none'
  }
}));

export default function EventBasicInfoManage(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  function getSteps() {
    return ['행사개요', '행사장소', '행사속성'];
  }

  const steps = getSteps();
  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <>
      <CssBaseline/>
      <Container maxWidth="lg" style={{minHeight: '80vh', marginBottom: '10vh'}}>
        <Typography variant="h5" className={classes.eventInfoTitle}>
          행사기본정보
        </Typography>
        <hr className={classes.mb30}/>
        <div className={classes.root}>
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
        {activeStep === 0 && <EventBasicInfoSummary eventInfo={props.eventInfo}/>}
        {activeStep === 1 && <EventBasicInfoPlace eventInfo={props.eventInfo}/>}
        {activeStep === 2 && <EventBasicInfoProperty eventInfo={props.eventInfo}/>}
      </Container>
    </>
  )
}



