import React, {useContext, useEffect, useState,} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Switch,
  Route,
  Link,
  useRouteMatch, Redirect
} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {useHistory} from "react-router-dom";
import FirebaseContext from "../../Context/Firebase";
import AuthenticationContext from "../../Context/Authentication";
import LoadingOverlay from "../../Component/LoadingOverlay/Index";
import * as Routes from "../../Constant/Routes";
import EventBasicInfoManage from './EventBasicInfo/EventBasicInfoManage';
import NoticeAppManage from './AppMenus/NoticeApp/NoticeAppManage';
import AgendaAppManage from './AppMenus/AgendaApp/AgendaAppManage';
import PostAppManage from "./AppMenus/PostApp/PostAppManage";
import AppMenuManage from './AppMenuManage/AppMenuManage';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {printDate} from "../../Utill/Functions";
import Button from "@material-ui/core/Button";
import StorageOutlinedIcon from '@material-ui/icons/StorageOutlined';
import AppsIcon from '@material-ui/icons/Apps';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import MenuIcon from '@material-ui/icons/Menu';
import WebViewAppManage from "./AppMenus/WebViewApp/WebViewAppManage";
import WebListAppManage from "./AppMenus/WebList/WebListAppManage";


const useStyles = makeStyles((theme) => ({
  eventTitleWrap: {
    textAlign: 'left',
    border: '1px solid #4050b5',
    padding: '30px 140px 30px 30px',
    borderRadius: '2px',
    position: 'relative'
  },
  eventStateChangBtn: {
    position: 'absolute',
    top: '16px',
    right: '16px'
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  appNavi: {
    padding: '0 10px 0 0'
  },
  contentWrap: {
    display: 'flex'
  },
  naviArea: {
    flex: 'none'
  },
  contentArea: {
    width: '100%',
    marginLeft: 'auto'
  },
  muiListItemText: {
    color: '#000',
    textDecoration: 'none'
  }
}));


export default function Index() {
  const classes = useStyles();
  const history = useHistory();
  const firebase = useContext(FirebaseContext)
  const authUser = useContext(AuthenticationContext)
  const eventId = history.location.pathname.split('/')[2];
  const [isLoading, setIsLoading] = useState(true)
  const [eventInfo, setEventInfo] = useState(null);
  const [open, setOpen] = React.useState(true);
  const {path, url} = useRouteMatch();

  useEffect(() => {

    const unsubscribe = firebase.firestore().collection('Events')
      .doc(eventId)
      .onSnapshot((result) => {
        if (result.exists) {
          const data = result.data();
          if (authUser !== data.createdBy) {
            history.push(Routes.DashBoard)
          }
          setEventInfo(data)
          setIsLoading(false)
        } else {
          history.push(Routes.DashBoard)
        }
      })
    return () => {
      unsubscribe();
    };
  }, [])
  const changEventState = (nowState) => {
    firebase.firestore().collection('Events')
      .doc(eventId)
      .update({
        state: nowState === 1 ? 2 : 1
      })
      .then(() => {
        if (nowState === 1) {
          alert(`${eventInfo.title} 행사를 공개로 변경하였습니다.`)
        } else {
          alert(`${eventInfo.title} 행사를 비공개로 변경하였습니다.`)
        }
      })

  }

  const handleClick = () => {
    setOpen(!open);
  };

  return !isLoading ? (
      <>
        <CssBaseline/>
        <Container maxWidth="lg" style={{minHeight: '80vh', marginBottom: '10vh'}}>
          <Box mt={5} mb={2}>
            <Grid container>
              <Grid item xs={12} md={12} lg={12} className={classes.eventTitleWrap}>
                <Typography variant="h5" style={{marginBottom: '5px'}}>
                  {eventInfo.title}
                </Typography>
                <Typography variant="body2">
                  {printDate(eventInfo.startAt.toDate())} ~ {printDate(eventInfo.endAt.toDate())}
                </Typography>
                <Button
                  className={classes.eventStateChangBtn}
                  variant="outlined"
                  color="primary"
                  onClick={() => changEventState(eventInfo.state)}
                >
                  {
                    eventInfo.state === 1 ? <span>행사 공개하기</span> : <span>행사 비공개하기</span>
                  }
                </Button>
              </Grid>
            </Grid>
          </Box>

          <div className={classes.contentWrap}>
            <div className={classes.naviArea}>
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classes.appNavi}
              >
                <Link to={`${url}/appMenu/eventBasicInfo`} className={classes.muiListItemText}>
                  <ListItem button>
                    <ListItemIcon>
                      <StorageOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText primary="행사 기본정보"/>

                  </ListItem>
                </Link>

                <ListItem button onClick={() => handleClick()}>
                  <ListItemIcon>
                    <AppsIcon/>
                  </ListItemIcon>
                  <ListItemText primary="앱 관리"/>
                  {open ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <Link to={`${url}/appMenu`} className={classes.muiListItemText}>
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          <MenuIcon/>
                        </ListItemIcon>
                        <ListItemText primary="메뉴"/>
                      </ListItem>
                    </Link>

                    {/* <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <StarBorder/>
                      </ListItemIcon>
                      <ListItemText primary="추가메뉴"/>
                    </ListItem> */}

                  </List>
                </Collapse>
              </List>

            </div>
            <div className={classes.contentArea}>


              <Switch>
                <Route exact path={`${path}/appMenu`}>
                  <AppMenuManage eventInfo={eventInfo}/>
                </Route>

                <Route exact path={`${path}/appMenu/eventBasicInfo`}>
                  <EventBasicInfoManage eventInfo={eventInfo}/>
                </Route>

                <Route exact path={`${path}/appMenu/noticeManage`}>
                  <NoticeAppManage eventInfo={eventInfo}/>
                </Route>

                <Route exact path={`${path}/appMenu/agendaManage`}>
                  <AgendaAppManage eventInfo={eventInfo} />
                </Route>
                <Route exact path={`${path}/appMenu/postManage`}>
                  <PostAppManage eventInfo={eventInfo} />
                </Route>

                <Route exaxt path={`${url}/appMenu/webviewManage`}>
                  <WebViewAppManage eventInfo={eventInfo} />
                </Route>

                <Route exact path={`${url}/appMenu/weblistManage`}>
                  <WebListAppManage eventInfo={eventInfo}/>
                </Route>
                <Redirect to={`${path}/appMenu`}/>
              </Switch>


            </div>
          </div>
        </Container>
      </>

    ) :
    (<LoadingOverlay/>)
    ;
}

