import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Snackbar from "@material-ui/core/snackbar";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
  snackbarStyleViaContentProps: {
    backgroundColor: '#fff',
    color: '#000'
  },

}));

export default function useToast({type, message, onClose, onExited}) {
  const classes = useStyles();
  const [showToast, setShowToast] = useState(false);
  const handleClose = () => {
    setShowToast(false);
    if (onClose) {
      onClose();
    }
  }
  const renderToast = () => (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      ContentProps={{
        className: classes.snackbarStyleViaContentProps
      }}
      open={showToast}
      autoHideDuration={5000}
      onClose={handleClose}
      onExited={onExited}
      message={message || ''}
      action={
        <>
          <IconButton
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon/>
          </IconButton>
        </>
      }
    />
  )
  return {
    setShowToast,
    renderToast,
  }
}
