import React, {useContext, useEffect, useState} from 'react';

import {createMuiTheme, makeStyles, MuiThemeProvider} from "@material-ui/core/styles";
import FirebaseContext from "../../../../Context/Firebase";
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {printDateTime,} from "../../../../Utill/Functions";
import MaterialTable from "material-table";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import * as siteConfig from "../../../../Constant/SiteConfig";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import GetAppIcon from "@material-ui/icons/GetApp";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import RemoveIcon from "@material-ui/icons/Remove";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import PostDetailModal from "./PostDetailModal";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
  eventInfoTitle: {
    paddingTop: '10px'
  },
  appMenuAddBtn: {
    float: 'right',
    marginLeft: '5px'
  },
  mb30: {
    margin: '15px 0 30px 0',
    height: '1px',
    backgroundColor: '#ccc',
    border: 'none'
  },
  tableHeader: {
    fontSize: '18px',
    fontWeight: 'bold'
  },
  noticeMangeBtn: {
    padding: '10px 20px',
    marginRight: '5px',
    background: '#fff',
    border: '1px solid #ccc',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  loadingWrap: {
    textAlign: 'center',
    width: '100%',
    padding: '10px 0'
  },
  appMenuTitleIcon: {
    position: 'relative',
    top: '3px',
    left: '5px',
    cursor: 'pointer'
  },
  editableInput: {
    width: '50%',
    padding: '5px 10px',
    display: 'inline'
  },
  avataImg: {
    borderRadius: '50%',
    width: '50px',
    height: '50px',
  }

}));

const theme = createMuiTheme();
export default function ReportListView(props) {
  const {eventInfo, menuKey} = props;
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isPostDetailModal, setIsPostDetailModal] = useState(false);
  const [postDetailItemId, setPostDetailItemId] = useState(null);
  const [reportsData, setReportsData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collectionGroup('Reports')
      .where('menuId', '==', menuKey)
      .orderBy('createdAt', 'desc')
      .onSnapshot((docResult) => {
        const resultDatas = [];
        docResult.forEach((doc) => {
          resultDatas.push({
            ...doc.data(),
            sortCreatedAt: doc.data().createdAt.seconds
          })
        })
        setReportsData(resultDatas);
        setIsLoading(false)
      })
    return () => {
      unsubscribe();
    }

  }, [])

  const openPostDeatilModal = (item) => {
    setPostDetailItemId(item)
    setSelectedRow(item.id)
    setIsPostDetailModal(true)
  }

  const closePostDetailModal = () => {
    setPostDetailItemId(null)
    setIsPostDetailModal(false)
  }
  const changeReportListHandlingState = (itemData) => {
    setSelectedRow(itemData.id)
    const resultValue = itemData && itemData.isHandling;
    if (itemData.targetCollection === 'Posts') {
      firebase.firestore().collection('Events')
        .doc(eventInfo.id)
        .collection('Posts')
        .doc(itemData.targetId)
        .collection('Reports')
        .doc(itemData.id)
        .update({
          isHandling: !resultValue
        })
    } else if (itemData.targetCollection === 'Comments') {
      firebase.firestore().collection('Events')
        .doc(eventInfo.id)
        .collection('Posts')
        .doc(itemData.postId)
        .collection('Comments')
        .doc(itemData.targetId)
        .collection('Reports')
        .doc(itemData.id)
        .update({
          isHandling: !resultValue
        })
    }
  }
  return !isLoading ?
    (
      <>
        <CssBaseline/>
        <Container maxWidth="lg" style={{overflow: 'auto', padding: '0'}}>
          <MuiThemeProvider theme={theme}>
            <MaterialTable
              actions={[
                {
                  icon: () => <MoreHorizOutlinedIcon/>,
                  tooltip: '자세히보기',
                  onClick: (event, rowData) => openPostDeatilModal(rowData)
                },
                rowData => ({
                  icon: () => <CheckCircleOutlineIcon/>,
                  tooltip: '처리상태변경',
                  onClick: (event, rowData) => changeReportListHandlingState(rowData),
                }),
              ]}
              columns={[
                {
                  title: '신고자',
                  field: 'displayName',
                  headerStyle: {
                    fontSize: '18px',
                    fontWeight: 'bold'
                  },
                },
                {
                  title: '이메일',
                  field: 'reporterId',
                  headerStyle: {
                    fontSize: '18px',
                    fontWeight: 'bold'
                  },
                },

                {
                  title: '신고일',
                  field: 'createdAt',
                  filtering: false,
                  headerStyle: {
                    fontSize: '18px',
                    fontWeight: 'bold'
                  },
                  render: (rowData) => printDateTime(rowData.createdAt.toDate())
                },
                {
                  title: '종류',
                  field: 'targetCollection',
                  filtering: false,
                  headerStyle: {
                    fontSize: '18px',
                    fontWeight: 'bold'
                  },
                  width: 100,
                  lookup: {
                    Comments: '댓글',
                    Posts: '게시글'
                  }
                },
                {
                  title: '상태',
                  field: 'isHandling',
                  filtering: false,
                  headerStyle: {
                    fontSize: '18px',
                    fontWeight: 'bold'
                  },
                  width: 100,
                  // lookup: {
                  //   true: '처리완료',
                  //   false: '대기',
                  // },
                  render: rowData => (rowData.isHandling === true ? <p>처리완료</p> : <p style={{color: 'red'}} > 처리대기중 </p>)
                }
              ]}
                data={reportsData}
                options={{
                  rowStyle: rowData => ({
                    backgroundColor: (selectedRow === rowData.id) ? '#EEE' : '#FFF'
                  }),
                  actionsColumnIndex: -1,
                  search: false,
                  pageSize: 5,
                  toolbar: false,
                  pageSizeOptions: [5, 10, 20, 30, 50],
                  exportButton: false,
                  filtering: true,
                }}

                localization={
                  {
                    ...siteConfig.tableSetLable,
                    grouping: {placeholder: '정렬하고자 하는 컬럼을 드래그해주세요.'},
                    toolbar: {exportName: ' CSV파일로 다운로드', nRowsSelected: '{0} 건이 선택되었습니다.'},
                    header: {actions: ''},
                  }
                }
                icons={{
                  Add: AddCircleIcon,
                  Check: CheckBoxIcon,
                  Clear: ClearIcon,
                  Delete: DeleteIcon,
                  DetailPanel: AddCircleIcon,
                  Edit: EditIcon,
                  Export: GetAppIcon,
                  Filter: SearchIcon,
                  FirstPage: FirstPageIcon,
                  LastPage: LastPageIcon,
                  NextPage: NavigateNextIcon,
                  PreviousPage: NavigateBeforeIcon,
                  ResetSearch: ClearIcon,
                  Search: SearchIcon,
                  SortArrow: ArrowDownwardIcon,
                  ThirdStateCheck: RemoveIcon,
                  ViewColumn: ViewColumnIcon,
                }}
                />
                </MuiThemeProvider>
                </Container>
                {
                isPostDetailModal &&
                <PostDetailModal
                isReportsModal
                reportInfo={postDetailItemId}
                eventInfo={eventInfo}
                postInfo={postDetailItemId}
                isModal={isPostDetailModal}
                closeModal={closePostDetailModal}
                />
                }
                </>
                )
                : (
                <div className={classes.loadingWrap}>
                <CircularProgress/>
                </div>
                )

                }
