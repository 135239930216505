import React, {useState, useContext,  useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {TextField} from 'formik-material-ui';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import * as Yup from 'yup';
import {Field, Formik, Form,} from 'formik';
import * as Routes from '../../Constant/Routes';
import FirebaseContext from '../../Context/Firebase';
import LoadingOverlay from "../../Component/LoadingOverlay/Index";
import AuthenticationContext from "../../Context/Authentication";
import logo from "../../images/logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: '280px',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(4, 4, 1, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  withdrawal: {
    margin: theme.spacing(0, 4),
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'right',
  },
  phoneAuth: {
    float: 'right',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  errorMessage: {
    color: '#f5564a',
    margin: '0'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  MuiInputInput: {
    border: '3px solid #ccc'
  },
  whiteBtn: {
    marginBottom: '5px',
  },
  tlc: {
    textAlign: 'center'
  },
  codeInput: {
    width: '100%',
    padding: '10px',
    textAlign: 'center',
    fontSize: '18px'
  }

}));

export default function MemberInfoModify() {

  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthenticationContext);
  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);


  const history = useHistory();


  useEffect(() => {
    firebase.firestore().collection('OrganizerUsers')
      .doc(authUser)
      .get()
      .then((doc) => {
        setUserInfo(doc.data());
        setIsLoading(false);
      })
  }, [])

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('성을 입력해주세요.'),
    lastName: Yup.string()
      .required('이름을 입력해주세요.'),
  });


  const onSubmit = (values) => {
    setIsLoading(true)
    const {email,firstName, lastName } = values;
    firebase.firestore().collection('OrganizerUsers')
      .doc(email)
      .update({
        firstName:firstName,
        lastName:lastName
      })
      .then(() => {
        window.alert('회원정보가 정상적으로 수정되었습니다.')
        history.push(Routes.DashBoard)
      })


  } //onSubmit End


  return !isLoading && (
    <>
      <Grid container component="main" className={classes.root}>
        <CssBaseline/>
        <Grid item xs={false} sm={false} md={7} className={classes.image}/>
        <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
              MemberShip
            </Typography>
            <Formik
              initialValues={{
                email: userInfo.email,
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,

              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {() => (
                <Form className={classes.form}>
                  <Field
                    disabled
                    component={TextField}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="이메일"
                    name="email"
                  />

                  <Field
                    disabled={false}
                    component={TextField}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="성"
                    name="firstName"
                  />

                  <Field
                    disabled={false}
                    component={TextField}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="이름"
                    name="lastName"
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    size="large"
                  >
                    회원정보 수정하기
                  </Button>
                </Form>
              )}
            </Formik>

          </div>
          <div className={classes.withdrawal}>
            <Button onClick={() => history.push(Routes.Withdrawal)}>
              계정탈퇴
            </Button>
          </div>
        </Grid>
      </Grid>
      {
        isLoading ?
          (<LoadingOverlay/>)
          : ''
      }
    </>
  );
}

